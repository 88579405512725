import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { CreateUserComponent } from '../user/create-user/create-user.component';

import { UserService } from '../user/user.service';
import { FreeCreateComponent } from '../free-create/free-create.component';
@Component({
  selector: 'app-manage-free-trial',
  templateUrl: './manage-free-trial.component.html',
  styleUrls: ['./manage-free-trial.component.scss']
})
export class ManageFreeTrialComponent implements OnInit {

  displayedColumns: string[] = ['userId','NO-OFDAYS', 'freeTrialName','default', 'STATUS',  'Action'];
  dataSource = new MatTableDataSource;
  isOrg: boolean = false

  isLoading: boolean = false
  @ViewChild(MatPaginator) paginator:any;

  constructor(public dialog: MatDialog, private gs: UserService,
    private Gs: GlobalserviceService, private auth: AuthService) {
  }

  ngOnInit() {
    console.log(this.gs.userList)
    this.Gs.get_free_trail().subscribe(
      (d: any) => {
        console.log(d)
        d.map(
          (e:any)=>{
              if(e.default  == true){
                e.active = true
              }
              if(e.default  == false){
                e.active = false
              }
            }
          )
        this.dataSource = new MatTableDataSource(d)
        this.dataSource.paginator = this.paginator;
      }
    )

  }
  CreateUserDialog() {
    const dialogRef = this.dialog.open(FreeCreateComponent, {
      disableClose: true,
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
     
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  editUser(element:any,i:any){
 
    element['index'] = i
    const dialogRef = this.dialog.open(FreeCreateComponent, {
      data:element,
      disableClose: true,
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
     
    });

  }

  toggleUpdate(toggle:any){
    // toggle.default = !toggle.default
    // toggle.active = !toggle.
    toggle['default'] = true

    console.log(toggle)
    // return

    this.Gs.put_free_trailid(toggle.freeTrialId,toggle).subscribe(
      
      (d:any)=>{
      Swal.fire({
        text:d.message,
        icon:'success'
      })
      setTimeout(()=>{
        window.location.reload();
      },1000)

      },
      err=>{
        console.log(err)
      }
    )
    
  }
}
