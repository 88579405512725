import { Component, Inject, OnInit,ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-view-location',
  templateUrl: './view-location.component.html',
  styleUrls: ['./view-location.component.scss']
})
export class ViewLocationComponent implements OnInit {
  viewLocationDetails:any[]=[];
  viewLocationDetails2:any[]=[];
  displayedColumns: string[] = ['sino','address'];
  dataSource = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator:any;
  currentPageOffset: number = 0; 
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    console.log('data', this.data);
    this.viewLocationDetails.push(this.data);
      this.viewLocationDetails.forEach((r) => {
        r.locationDetails.forEach((detail) => {
          this.viewLocationDetails2.push(detail);
        });
      });
    this.dataSource = new MatTableDataSource(this.viewLocationDetails2);
    console.log(this.dataSource);
    console.log('viewLocationDetails',this.viewLocationDetails2);
    console.log('dataSource',this.dataSource);
    this.dataSource.paginator = this.paginator;
  }

  onPageChange(event: any) {
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }
}
