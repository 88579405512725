import { Injectable, Output, EventEmitter } from '@angular/core';
import { globalTimeOut, url } from './properties';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, timeout, switchMap } from 'rxjs/operators';
import { identifierModuleUrl } from '@angular/compiler';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
// import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GlobalserviceService {
 


  myFile: any;


  constructor(private http: HttpClient) { }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  //---------------------getmethod sample---------------------------------

  // usertype
  get_usertype() {
    return this.http.get<any[]>(`${url}user-type`)
  }
  get_user_org(org_id: any) {
    return this.http.get<any[]>(`${url}user/organization/${org_id}`)
  }
  getClientbyOragnization(org_id: any) {
    return this.http.get<any[]>(`${url}client/organization/${org_id}`)
  }

  getClientbyClientId(ClinetId: any) {
    return this.http.get<any[]>(`${url}client/${ClinetId}`)
  }
  updateClient(ClinetId: any,data:any) {
    return this.http.put<any[]>(`${url}client/${ClinetId}`,data)
  }

 
  get_client_org(org_id: any) {
    return this.http.get<any[]>(`${url}client/organization/${org_id}`)
}
  assign_Employee_shifts(data:any){
    return this.http.post(`${url}shift-employee-assign/multiple`,data); 
   }
   assign_OneEmployee_shift(data:any){
    return this.http.post(`${url}shift-employee-assign/`,data); 

   }
  get_primary_users_user(primaryUserId: any) {
    return this.http.get<any[]>(`${url}user/primaryUser/${primaryUserId}`)
  }

  getLoginLogoutReportsforPrimaryUser(orgId:any){
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/org/${orgId}`)
  }

  getLoginLogoutReportsforEmployee(empId:any){
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/employee/${empId}`)
  }

  getLoginLogoutReportsforBuddyUser(userId:any){
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/buddyUser/${userId}`)
  }

 
  getCurrentLocation(createdByUser: any) {
    return this.http.get<any[]>(`${url}livelocation/reports/createdByUser/${createdByUser}`)
  }

  getLoginLogoutReports(orgid:any,userid: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/${orgid}/${userid}`)
  }


  // https://printlok.jespersoft.com:6008/trackingsupervisor/employee_shift_login_details/login/logout/report/1/2
  assignClientLocations(clientId:number,body:any){
    return this.http.post<any[]>(`${url}client/${clientId}/assign-locations`,body)
  }
  // localhost:6008/trackingsupervisor/client/1/assign-locations

  // Filter User Api
  get_userlistv2(userid: any) {
    return this.http.get(`${url}v2/reports/${userid}`)
  }
 // Filter User and sD and ED Api
  get_userandDatelistv2(startDate:any,endDate:any,userid: any) {
    return this.http.get(`${url}v2/reports/${startDate}/${endDate}/${userid}`)
  }
  // Filter User and sD and ED and STime and ETime Api
  get_userandTimelistv2(startDate:any,endDate:any,startTime:any,endTime:any,userid: any) {
    return this.http.get(`${url}reports/date/time/${startDate}/${endDate}/${startTime}/${endTime}/${userid}`)
  }

  // Filter User Api
  get_userlist(userid: any) {
    return this.http.get(`${url}reports/${userid}`)
  }
  // Filter User and sD and ED Api
  get_userandDatelist(startDate:any,endDate:any,userid: any) {
    return this.http.get(`${url}reports/${startDate}/${endDate}/${userid}`)
  }
  // Filter User and sD and ED and STime and ETime Api
  get_userandTimelist(startDate:any,endDate:any,startTime:any,endTime:any,userid: any) {
    return this.http.get(`${url}reports/date/time/${startDate}/${endDate}/${startTime}/${endTime}/${userid}`)
  }

  get_user(userid: any) {
    return this.http.get(`${url}/${userid}`)
  }


  Get_Country() {
    return this.http.get<any[]>(`${url}country`)
  }
  getEmployeeBusinessTravelRequest_EmailID(reportingto: any, businesssTravelStatus: any) {
    return this.http.get(`${url}business_travel/reportingto/${reportingto}/${businesssTravelStatus}`)
  }

  attendanceReports(fromDate:any,toDate:any){

    return this.http.get(`${url}employee_shift_login_details/v2/attendance/report/${fromDate}/${toDate}`)
  }
  attendanceReportsUser(fromDate:any,toDate:any,userId:any){
    return this.http.get(`${url}employee_shift_login_details/v2/attendance/report/user/${fromDate}/${toDate}/${userId}`)
  }
  attendanceReportsPrimary(fromDate:any,toDate:any,org:any){
    return this.http.get(`${url}employee_shift_login_details/v2/attendance/report/org/${fromDate}/${toDate}/${org}`)
  }
  attendanceReportsSecondary(fromDate:any,toDate:any,userId:any){
    return this.http.get(`${url}employee_shift_login_details/v2/attendance/report/user/${fromDate}/${toDate}/${userId}`)
  }
// https://test-printlok.jesperapps.com:6002/trackingsupervisor/employee_shift_login_details/v2/attendance/report/2024-09-26/2024-09-28
  getCountry() {
    return this.http.get(`${url}country`);
  }
  getState(countryId: any) {
    return this.http.get(`${url}state/${countryId}`);
  }
  getCity(stateId: any) {
    return this.http.get(`${url}city/${stateId}`);
  }
  getIndustryType() {
    return this.http.get(`${url}industry-type`);
  }
  getUser_by_Id(id: any) {
    return this.http.get(`${url}user/${id}`);
  }
  get_organziation() {
    return this.http.get(`${url}organization`);
  }
  getUser_by_location(primaryUserId: any, userId: any) {
    return this.http.get(`${url}work-place/${primaryUserId}/${userId}`);
  }

  addOrganization(data: any) {
    return this
      .http
      .post(`${url}organization/superadmin/registration`, data);
  }

  adduser(data :any) {
    return this
    .http
    .post(`${url}superadmin/user`, data);
  }

  forgetpassword(data: any) {
    return this
    .http
    .post(`${url}forgotpassword`, data);
  }

  forgetpasswordcode(data: any) {
    return this
    .http
    .post(`${url}login/forgotpassword`, data);
  }

  uploaduser(formData: FormData) {
    return this
      .http
      .post(`${url}user/uploadfile`, formData);
  }
  multipleuser(final_json: any) {
    return this
    .http
    .post(`${url}multiple/user`, final_json);
  }

   getuserlocation(userId: any) {
    
    return this
    .http
    .get(`${url}livelocation/${userId}`);

    
  }
 

  RegisetrOrgUser(data: any) {
    return this
      .http
      .post(`${url}organization/user/registration`, data);
  }

  Create_Org_User(data: any) {
    return this
      .http
      .post(`${url}user`, data);
  }


  createClient(data:any){

    return this.http.post(`${url}client/`,data)
  }

  // 192.168.0.197:6008/trackingsupervisor/client/
  Assign_Location(data: any) {
    return this
      .http
      .post(`${url}multiple/work-place`, data);
  }

  Assign_Instant_Location(f_d: any) {
    return this
    .http
    .post(`${url}multiple/instant/work-place`, f_d);
  }

  get_user_induvar(){
    return this.http.get(`${url}primaryuser`);
  }


  get_location(userId: any) {
    return this.http.get(`${url}locationDetails/user/${userId}`);
  }
  get_location_byClient(clientId:any){
    return this.http.get(`${url}locationDetails/client/${clientId}`);

  }

  get_loginReport(organizationId:any,userId: any) {
    return this.http.get(`${url}employee_shift_login_details/login/logout/report/${organizationId}/${userId}`);
  }














  // leaveType
 
  // getWFHRequest(applyTo, categoryId, status) {
  //   return this.http.get(`${url}wfh/${applyTo}/${categoryId}/${status}`)
  // }
  getWFHRequest(reportingTo: any, status: any) {
    return this.http.get(`${url}wfh/reportingto/${reportingTo}/${status}`)
  }
  getPermission_Manage(id: any) {
    return this.http.get(`${url}permissions/pending/reporting/${id}`)
  }
  // get travel mode
  getTravel_Mode() {
    return this.http.get(`${url}travelmode`);
  }
  // get business travel
  getMyBusinessTravel_List(empId: any, categoryId: any) {
    return this.http.get(`${url}business_travel/${empId}/${categoryId}`)
  }

  getListTypeById(listTypeId: any) {
    return this.http.get(`${url}list-type-values/${listTypeId}`)
  }
  getAdjustment() {
    return this.http.get(`${url}leave_adjustment/organization/1`);
  }
  getOrganization() {
    return this.http.get(`${url}organization`);
  }
  //--------------------------------post------------------------------------
  // business apply
  Apply_Business_Travel_Request(data: any) {
    return this.http.post(`${url}business_travel`, data)
  }
  // action
  //
  createLeaveType(data: any) {
    return this.http.post(`${url}leave_type`, data)
  }
  // withdraw
  withdraw(data: any) {
    return this.http.post(`${url}leave_withdraw`, data)
  }
  //leave Balance
  leaveBalance(data: any) {
    return this.http.post(`${url}leave_balance`, data)
  }
  // employee
  CreateEmployee(Form: FormData) {
    return this
      .http
      .post(`${url}employee`, Form);
  }
  addLeave(Form: any) {
    return this
      .http
      .post(`${url}leave_request`, Form);
  }
  applyPermission(Form: any) {
    return this
      .http
      .post(`${url}permissions`, Form);
  }
  // wrok from home
  addWfm(Data: any) {
    return this
      .http
      .post(`${url}wfh`, Data);
  }
  addAdjustment(Data: any) {
    return this
      .http
      .post(`${url}leave_adjustment`, Data);
  }

  //--------------------------------update------------------------------------
  //  leave status update
  UpdateLeaveStatus(id: any, Form: any) {
    return this
      .http
      .put(`${url}leave_request_status/${id}`, Form);
  }
  updateLeaveType(id: any, data: any) {
    return this.http.put(`${url}leave_type/${id}`, data)
  }
  UpdateEmployee(id: any, data: FormData) {
    return this.http.put(`${url}employee/${id}`, data)
  }

  UpdateEmployeeProfile(id: any, data: any) {
    return this.http.put(`${url}employee_image/${id}`, data)
  }
  // update Password
  updateAccountSettings(id: any, Form: any) {
    return this
      .http
      .put(`${url}change_password/${id}`, Form);
  }
  Actionwfh(id: any, data: any) {
    return this
      .http
      .put(`${url}wfh_status/${id}`, data);
  }
  AcctionBusiness(id: any, data: any) {
    return this
      .http
      .put(`${url}business_travel_status/${id}`, data);
  }
  //--------------------------------delete------------------------------------
  deleteLeaveType(id: any) {
    return this.http.delete(`${url}leave_type/${id}`)
  }
  deleteEmployee(employeeId: number) {
    return this
      .http
      .delete<any>(`${url}employee/${employeeId}`);
  }
  deleteLeave(leaveId: any, data: any) {
    return this
      .http
      .put(`${url}leave_request_status/${leaveId}`, data);
  }
  // +++++++++++++++++++++++++++++ withdraw ++++++++++++++++++++++++++++++++++++++++++++++//

  employee_withdraw_list_action(empId: any, mail: any) {
    return this.http.get(`${url}leave_withdraw/employee/${empId}/${mail}`)

  }
  withdrawAction(id: any, data: any) {
    return this.http.put(`${url}leave_withdraw_status/${id}`, data)
  }

  Permission_Status(id: any, data: any) {
    return this.http.put(`${url}permissions_status/${id}`, data)
  }

  getAll_withdrawList(mail: any) {
    return this.http.get(`${url}leave_withdraw/all/${mail}`)

  }

  post_subscribe(updatetoken :any) {
    return this
    .http
    .post(`${url}doNotTrack/subscribe`, updatetoken);
}

Create_Location(locationdata : any) {
  return this
  .http
  .post(`${url}locationDetails/user`, locationdata);
}

getLocation_by_ID(id: any) {
  return this.http.get(`${url}locationDetails/location/${id}`)

}

Update_Location(locationdata: any) {
  return this
  .http
  .put(`${url}locationDetails/${locationdata.locationId}`, locationdata);
}

get_freetrial() {
  return this.http.get(`${url}freetrial/`)
}

get_freetrial_default() {
  return this.http.get(`${url}freetrial/isdefault`);
}

get_free_trail(){
  return this.http.get(`${url}freetrial`);
}
get_free_trailid(freetrialId:any){
  return this.http.get(`${url}freetrial/${freetrialId}`); 
}
put_free_trailid(freetrialId:any ,msg: any){
  return this.http.put(`${url}freetrial/${freetrialId}`,msg); 
}

addtrial(data: any){
  return this
  .http
  .post(`${url}freetrial`, data);
}

put_free_update(freetrialId:any ,msg: any){
  return this.http.put(`${url}freetrial/update/${freetrialId}`,msg); 
}

get_dont_track_byid(id: any) {
  return this.http.get<any>(`${url}track/${id}`)
}

get_dont_track(primaryUserId: any) {
  return this.http.get<any[]>(`${url}track/byprimaryuser/${primaryUserId}`)
}
put_dont_track(trackId: any, msg: any) {
  return this.http.put<any>(`${url}track/${trackId}`,msg)
}

Update_Org_User(z: any, userId: any) {
  return this.http.put<any>(`${url}user/${userId}`,z)
}

get_report_byuser(fromDate: any, toDate: any, id: any) {
  return this.http.get<any[]>(`${url}reports/${fromDate}/${toDate}/${id}`)
}

get_current_workplace(today: string | null, id: any) {
  return this.http.get<any[]>(`${url}work-place/current/${today}/${id}`)
}
// Manage Geofence
get_Gefence_list(userId:number) {
  return this.http.get<any[]>(`${url}geofence/user/${userId}`)
}
// Manage Geofence List
get_Geofencelist(orgId:number) {
  return this.http.get<any[]>(`${url}geofence/org/${orgId}`)
}
// add Geofence
createGeofence(data: any) {
  return this.http.post(`${url}geofence`, data)
}
// tatus active and inactive api
updateGeofenceStatus(id: any, status: any) {
  return this
    .http
    .put(`${url}geofence/status/${id}/${status}`, status);
}
userupdateStatus(id: any, status: any) {
  return this
    .http
    .put(`${url}user/deactivate/${id}/${status}`, status);
}

updateGeofence(geofenaceId: any, updateData: any) {
  return this.http.put<any>(`${url}geofence/${geofenaceId}`,updateData)
}

getGeofenceById(geofenaceId: number) {
  return this.http.get(`${url}geofence/${geofenaceId}`)
}
// Assign Geofence
get_User_list() {
  return this.http.get<any[]>(`${url}user`)
}
get_Geofence_list(userId: number) {
  return this.http.get<any[]>(`${url}geofence/user/status/${userId}`)
}
get_employee_byClient_list(storeClientId: any) {
  return this.http.get<any[]>(`${url}client/${storeClientId}/employees`)
}
get_user_AllemployeeList(orgId:any){
  return this.http.get<any[]>(`${url}user/organization/${orgId}`)

}
get_shift_byClient_list(){
  return this.http.get<any[]>(`${url}shift/`)
}
getEmployeeNameList(keywords:any) {
  return this.http.get(`${url}employee/filter/keyword/${keywords}`)
  .pipe(map(data=>data));
}
getUserNameList(keywords:any) {
  return this.http.get(`${url}user/filter/keyword/${keywords}`)
  .pipe(map(data=>data));
}
get_usergeofencelist(userid: any) {
  return this.http.get<any>(`${url}userGeofence/user/${userid}`)
}
get_geofencelist(geofenceId: any) {
  return this.http.get<any>(`${url}userGeofence/geofence/${geofenceId}`)
}
get_EmployeeOnly(empId:any){
  return this.http.get(`${url}employee/${empId}`)

}

get_userId(id:any){
  return this.http.get(`${url}user/${id}`)

}
get_userandgeofencelist(geofenceId: any,userid: any) {
  return this.http.get<any>(`${url}userGeofence/geofence/user/${geofenceId}/${userid}`)
}
// Assign Geofence 
createassignGeofence(data: any) {
  return this.http.post(`${url}userGeofence/multiple`, data)
}
createClientAssign(clientId: any,data:any) {
  return this.http.post(`${url}client/${clientId}/assign-employees`,data)
}
// active and inactive status
// updateAssignGeofenceStatus(id: any, status: any) {
//   let result =  this.http.put<any>(`${url}employeeclientassign/deactive/${id}/${status}`).pipe(map(data=>data))
//   return result;
// }
updateAssignGeofenceStatus(id: any, status: any) {
  return this
    .http
    .put(`${url}userGeofence/status/${id}/${status}`, status);
}

// Get Client List
getClientList(organizationId:any)
{
  return this.http.get<any[]>(`${url}client/organization/${organizationId}`)
}
// ClientId based get Client Details
getClientId(clientId)
{
  return this.http.get<any>(`${url}client/${clientId}`)
}
getclientByOrg(orgId: any) {
  return this.http.get<any[]>(`${url}client/organization/${orgId}`)
}
getemployeeseachbyEmpNoKeyword(employeecode: any,orgId: any){
  return this.http.get<any[]>(`${url}user/filter/keyword/empNum/${employeecode}/${orgId}`)
}
getEmployeebyempcode(employeecode: any) {
  return this.http.get<any[]>(`${url}employee/filter/keyword/${employeecode}`)
}
getclientEmployeebyempcode(employeecode: any, clientId) {
  return this.http.get<any[]>(`${url}employee/employeenumber/client/${employeecode}/${clientId}`)
}
getbuddyuserByorg(usertype: any, orgId:any) {
  return this.http.get<any[]>(`${url}user/usertype/${usertype}/organization/${orgId}`)
}
AssignEmployeeOT(payload:any){
  return this.http.post<any[]>(`${url}ot-shift-assignment/multiple`,payload)
}
// get_empOTAssignlistold(assigneduserId:any){
//   return this.http.get<any[]>(`${url}ot-shift-assignment/assignedBy/${assigneduserId}`)
// }
// get_EmpOTAssignbydateold(date:any){
//   return this.http.get<any[]>(`${url}ot-shift-assignment/date/${date}`)
// }
get_empOTAssignlist(orgId:any){
  return this.http.get<any[]>(`${url}ot-shift-assignment/organization/${orgId}`)
}
get_EmpOTAssignbydate(date:any,orgId:any){
  return this.http.get<any[]>(`${url}ot-shift-assignment/date/${date}/organization/${orgId}`)
}
get_empOTAssignlistbuddyuser(buddyuserId:any){
  return this.http.get<any[]>(`${url}ot-shift-assignment/buddyUser/${buddyuserId}`)
}
get_EmpOTAssignbydatebuddyuser(date:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}ot-shift-assignment/buddyUser/date/${date}/${buddyuserId}`)
}
getshiftemployeeassignbyEmp(empId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/employee/${empId}/current`)
}
getshiftemployeeassignbyEmpanddate(date:any,empId:any){
  return this.http.get<any[]>(`${url}ot-shift-assignment/employee/date/${date}/${empId}`)
}
getshiftemployeeassignbydateandEmp(empId:any, date:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/employee/${empId}/${date}`)
}
getdesignation(){
  return this.http.get<any[]>(`${url}designation`)
}

get_Client_only(client:any){
  return this.http.get<any[]>(`${url}client/${client}`)

}
get_ClientEmployee(client:any,employeeID:any){
  return this.http.get<any[]>(`${url}client/employee/${client}/${employeeID}`)

}
//..........................shift......................................................................................................
// shift_All_fieldsold(clientId:any,shiftId:any,location:any,startdate:any,enddate:any){
//   return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/location/start-date/end-date/${clientId}/${shiftId}/${location}/${startdate}/${enddate}`)
//   //shift-employee-assign/client/shift/location/start-date/end-date/1/1/2/2024-11-01/2024-11-30
// }
// shift_onlyClient_fieldsold(clientId:any){
//   return this.http.get<any[]>(`${url}shift-employee-assign/client/${clientId}`)
// }
// shift_onlyDate_fieldsold(startdate:any,enddate:any){
//   return this.http.get<any[]>(`${url}shift-employee-assign/start-date/end-date/${startdate}/${enddate}`)
//   //shift-employee-assign/start-date/end-date/2024-11-01/2024-11-30
// }
// shift_ClientDate_fieldsold(clientId:any,startdate:any,enddate:any){
//   return this.http.get<any[]>(`${url}shift-employee-assign/client/start-date/end-date/${clientId}/${startdate}/${enddate}`)
//   //shift-employee-assign/client/start-date/end-date/1/2024-10-01/2024-10-31
// }
// get_onlyShiftold(shiftId:any){
//   return this.http.get<any[]>(`${url}shift-employee-assign/shift/${shiftId}`)
//   // shift-employee-assign/shift/1
// }
// get_shift_with_datesold(shiftId:any,startdate:any,enddate:any){
//   return this.http.get<any[]>(`${url}shift-employee-assign/shift/start-date/end-date/${shiftId}/${startdate}/${enddate}`)
//   //shift-employee-assign/shift/start-date/end-date/1/2024-11-01/2024-11-30
// }
// get_shift_with_dates_clientold(clientId:any,shiftId:any,startDate:any,enddate:any){
//   return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/start-date/end-date/${clientId}/${shiftId}/${startDate}/${enddate}`)
//   // shift-employee-assign/client/shift/start-date/end-date/1/1/2024-11-01/2024-11-30
// }
// get_client_locationold(clientId:any,location:any){
//   return this.http.get<any[]>(`${url}shift-employee-assign/client/location/${clientId}/${location}`)
// ///shift-employee-assign/client/location/1/2
// }
// get_shift_with__client_locationold(clientId:any,shiftId:any,location:any){
//   return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/location/${clientId}/${shiftId}/${location}`)
// //shift-employee-assign/client/shift/location/1/1/2
// }
// get_client_dates_locationold(clientId:any,location:any,startDate:any,endDate:any){
//   return this.http.get<any[]>(`${url}shift-employee-assign/client/location/start-date/end-date/${clientId}/${location}/${startDate}/${endDate}`)
// //shift-employee-assign/client/location/start-date/end-date/1/2/2024-10-01/2024-10-31
// }
// get_Client_Shiftold(clientdId:any,shiftId:any){
//   return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/${clientdId}/${shiftId}`)
//   //shift-employee-assign/client/shift/1/1
// }
//..........................shift with organization......................................................................................................
shift_All_fields(clientId:any,shiftId:any,location:any,startdate:any,enddate:any,orgId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/location/start-date/end-date/${clientId}/${shiftId}/${location}/${startdate}/${enddate}/organization/${orgId}`)
  //shift-employee-assign/client/shift/location/start-date/end-date/1/1/2/2024-11-01/2024-11-30
}
shift_onlyClient_fields(clientId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/client/${clientId}`)
}
shift_onlyDate_fields(startdate:any,enddate:any,orgId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/start-date/end-date/${startdate}/${enddate}/organization/${orgId}`)
  //shift-employee-assign/start-date/end-date/2024-11-01/2024-11-30
}
shift_ClientDate_fields(clientId:any,startdate:any,enddate:any,orgId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/client/start-date/end-date/${clientId}/${startdate}/${enddate}/organization/${orgId}`)
  //shift-employee-assign/client/start-date/end-date/1/2024-10-01/2024-10-31
}
get_onlyShift(shiftId:any,orgId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/shift/${shiftId}/organization/${orgId}`)
  // shift-employee-assign/shift/1
}
get_shift_with_dates(shiftId:any,startdate:any,enddate:any,orgId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/shift/start-date/end-date/${shiftId}/${startdate}/${enddate}/organization/${orgId}`)
  //shift-employee-assign/shift/start-date/end-date/1/2024-11-01/2024-11-30
}
get_shift_with_dates_client(clientId:any,shiftId:any,startDate:any,enddate:any,orgId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/start-date/end-date/${clientId}/${shiftId}/${startDate}/${enddate}/organization/${orgId}`)
  // shift-employee-assign/client/shift/start-date/end-date/1/1/2024-11-01/2024-11-30
}
get_client_location(clientId:any,location:any,orgId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/client/location/${clientId}/${location}/organization/${orgId}`)
///shift-employee-assign/client/location/1/2
}
get_shift_with__client_location(clientId:any,shiftId:any,location:any,orgId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/location/${clientId}/${shiftId}/${location}/organization/${orgId}`)
//shift-employee-assign/client/shift/location/1/1/2
}
get_client_dates_location(clientId:any,location:any,startDate:any,endDate:any,orgId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/client/location/start-date/end-date/${clientId}/${location}/${startDate}/${endDate}/organization/${orgId}`)
//shift-employee-assign/client/location/start-date/end-date/1/2/2024-10-01/2024-10-31
}
get_Client_Shift(clientdId:any,shiftId:any,orgId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/${clientdId}/${shiftId}/organization/${orgId}`)
  //shift-employee-assign/client/shift/1/1
}

//..........................shift with buddyuser......................................................................................................
shift_All_fieldsbuddyuser(clientId:any,shiftId:any,location:any,startdate:any,enddate:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/shift/location/start-date/end-date/${clientId}/${shiftId}/${location}/${startdate}/${enddate}/${buddyuserId}`)
}
shift_onlyClient_fieldsbuddyuser(clientId:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/${clientId}/${buddyuserId}`)
}
shift_onlyDate_fieldsbuddyuser(startdate:any,enddate:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/start-date/end-date/${startdate}/${enddate}/${buddyuserId}`)
}
shift_ClientDate_fieldsbuddyuser(clientId:any,startdate:any,enddate:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/start-date/end-date/${clientId}/${startdate}/${enddate}/${buddyuserId}`)
}
get_onlyShiftbuddyuser(shiftId:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/shift/${shiftId}/${buddyuserId}`)
}
get_shift_with_datesbuddyuser(shiftId:any,startdate:any,enddate:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/shift/start-date/end-date/${shiftId}/${startdate}/${enddate}/${buddyuserId}`)
}
get_shift_with_dates_clientbuddyuser(clientId:any,shiftId:any,startDate:any,enddate:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/shift/start-date/end-date/${clientId}/${shiftId}/${startDate}/${enddate}/${buddyuserId}`)
}
get_client_locationbuddyuser(clientId:any,location:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/location/${clientId}/${location}/${buddyuserId}`)
}
get_shift_with__client_locationbuddyuser(clientId:any,shiftId:any,location:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/shift/location/${clientId}/${shiftId}/${location}/${buddyuserId}`)
}
get_client_dates_locationbuddyuser(clientId:any,location:any,startDate:any,endDate:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/location/start-date/end-date/${clientId}/${location}/${startDate}/${endDate}/${buddyuserId}`)
}
get_Client_Shiftbuddyuser(clientdId:any,shiftId:any,buddyuserId:any){
  return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/shift/${clientdId}/${shiftId}/${buddyuserId}`)
}
updateDeviceId(userId:any,OrganizationId:any,payload){
  return this.http.put<any[]>(`${url}user/deviceId/${userId}/${OrganizationId}`,payload)
}
}


