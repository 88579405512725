import { Component, OnInit, ViewChild } from '@angular/core';
import { LiveTrackComponent } from '../Components/live-track/live-track.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../Components/user/user.service';
import { GlobalserviceService } from '../providers/globalservice.service';
import { AuthService } from '../Authenticaton/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-previous-record-report',
  templateUrl: './previous-record-report.component.html',
  styleUrls: ['./previous-record-report.component.scss']
})
export class PreviousRecordReportComponent implements OnInit {

  displayedColumns: string[] = ['userId', 'name', 'email', 'phoneNumber', 'userType', 'Action'];
  dataSource = new MatTableDataSource;
  isOrg: boolean = false
   userlist : any = [];
  @ViewChild(MatPaginator) paginator:any;
  isLoading: boolean = false;
  currentPageOffset: number = 0; 
  constructor(public dialog: MatDialog,  private gs: UserService,  private Gs: GlobalserviceService ,private auth: AuthService) { }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    console.log(this.gs.userList)
    // this.dataSource = this.gs.userList
    let  userdata = this.auth.getLocalStorageData();

    if (this.auth.getLocal_Org()?.organizationId) {
      this.isOrg = true
      let id = this.auth.getLocal_Org().organizationId
      console.log(id)
      this.Gs.get_user_org(id).subscribe((a: any) => {
        a.map(
          (e: any)=>{
              if(userdata.userType[0].userTypeName == "BUDDY USER")
              {
                
               if(e.userId !=  userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER") 
                && e.buddyUser?.userId == userdata.userId){
                 console.log(e)
                  this.userlist.push(e);
               }
              }else {
               if(e.userId !=  userdata.userId  ){
                 console.log(e)
                  this.userlist.push(e);
               }
              }
         
         }
       )
        this.dataSource = new MatTableDataSource(this.userlist);
        this.dataSource.paginator = this.paginator;
        console.log(a);
   
      })
    } else {
      let id = this.auth.getLocalStorageData()?.userId;
      this.Gs.get_primary_users_user(id).subscribe((a: any) => {
        a.map(
          (e: any)=>{
           if(e.userId != id){
           //  console.log(e)
              this.userlist.push(e);
           }
         }
       )
   
        this.dataSource = new MatTableDataSource(this.userlist);
        this.dataSource.paginator = this.paginator;
        console.log(a)
      }
      ,
        err => {
          console.log
          this.isLoading = false
          this.dialog.closeAll()
          Swal.fire(
            err.error.description,
            "",
            'error'
          )
        })
    }
  }

  trackUser(element :any)
  {
    const dialogRef = this.dialog.open(LiveTrackComponent, {
      width: '750px',
      data: element,
      disableClose: true
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onPageChange(event: any) {
    // console.log("index",event.pageIndex);
    // console.log("size",event.pageSize);
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }

}
