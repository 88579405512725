import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'; 
import { AssignClientserviceService} from '../app-assign-client-location/assign-clientservice.service';
@Component({
  selector: 'app-alias-dialog',
  templateUrl: './alias-dialog.component.html',
  styleUrls: ['./alias-dialog.component.scss']
})
export class AliasDialogComponent implements OnInit {
  aliasForm: FormGroup;
  errorMessage: string | null = null;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AliasDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private assignClietservice:AssignClientserviceService
  ) { }

  ngOnInit(): void {
    console.log("data",this.data);
    this.aliasForm = this.fb.group({
      aliasName: ['', [Validators.required]],
    });
    //Patch marker value in dialogbox
    // if(this.data!=undefined)
    // {
    //   if(this.data.aliasName!="Default Drag Marker")
    //   {
    //     this.aliasForm.patchValue({
    //       aliasName:this.data.aliasName
    //     })
    //     console.log(this.aliasForm);
    //   }
    // }
   
  }

  onSubmit() {
    if (this.aliasForm.valid) {
      console.log("Dialog Submit");
      let s=true;
      this.assignClietservice.setStatus(s);
      // Close the dialog and return the alias name
      this.dialogRef.close(this.aliasForm.value.aliasName);
    } else {
      // Show an error message if the form is invalid
      this.errorMessage = 'Alias name is required.';
    }
  }

  onCancel() {
    console.log("Dialog Cancel");
    // // Instead of closing the dialog, you can just show an error message
    // if (this.aliasForm.get('aliasName')?.invalid) {
    //   this.errorMessage = 'Alias name is required.';
    // } else {
     let s=false;
      this.assignClietservice.setStatus(s);
      this.dialogRef.close(); // You can close if no error
    // }
  }
}
