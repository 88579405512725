<div class="row pl-5" style="padding: 15px;">
    <div class="col-lg-2 col-md-2 col-sm-12">
        <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
          <mat-label>Start Date</mat-label> 
          <input matInput  [(ngModel)]="startdate1"
            placeholder="Choose a Date"   (dateChange)="startdate($event)" 
             [matDatepicker]="picker1">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <span class="error_msg text-danger" *ngIf="staDate_required">*Please select startdate</span>
        <span class="error_msg text-danger" *ngIf="startDate_required">*Please select vaild startdate</span> 
    </div>
      <!-- </div> -->
      <div class="col-lg-2 col-md-2 col-sm-12">
        <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
            <mat-label>End Date</mat-label> 
            <input matInput  [(ngModel)]="enddate1"
              placeholder ="Choose a Date" (dateChange)="enddate($event)"  [min]="startDate"
             [matDatepicker]="picker2">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <span class="error_msg text-danger" *ngIf="endDate_required">*Please select enddate</span> 
        <span class="error_msg text-danger" *ngIf="endDate_error">Please select vaild enddate</span> 
        <!-- <span class="error_msg" >Please select end date</span> 
        <span class="error_msg" >End date should be greater than start date</span>   -->
      </div>
      <div class="col-lg-2 col-md-2 col-sm-12">
        <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
            <mat-label>Choose Shift</mat-label> 
            <mat-select placeholder="Select Shift" [(ngModel)]="shiftid"  (selectionChange)="isselecteShifts($event)">
            <mat-option [value]=undefined> -- Select Shift -- </mat-option>
            <!-- <mat-option value="Morning">Morning</mat-option> -->

            <mat-option *ngFor="let shift of shifts" [value]="shift.shiftId">{{ shift.shiftName }}
            </mat-option>
            </mat-select>
        </mat-form-field>
    <!-- <span class="error_msg" *ngIf="user_required">*Please select Geofence Name</span>  -->
</div>
    <div class="col-lg-2 col-md-2 col-sm-12">
            <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                <mat-label>Choose Client</mat-label> 
                <mat-select placeholder="Select Client" [(ngModel)]="clicentName"  (selectionChange)="isselectedname($event)" (selectionChange)=" onClientSelection($event)">
                <mat-option [value]=undefined> -- Select Client -- </mat-option>
                <mat-option *ngFor="let geofence of geoList"  [value]="geofence.clientId">{{geofence.clientCode}}-{{geofence.clientName}}</mat-option>
                </mat-select>
            </mat-form-field>
        <!-- <span class="error_msg" *ngIf="user_required">*Please select Geofence Name</span>  -->
    </div>
    <div class="col-lg-2 col-md-2 col-sm-12">
      <!-- <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
          <mat-label>Choose Employee</mat-label> 
          <mat-select placeholder="Select Employee" [(ngModel)]="employee" [disabled]="!isClientSelected" (selectionChange)="isselectedemployesname($event)">
          <mat-option value="0"> -- Select Employee -- </mat-option>
          <mat-option *ngFor="let emp of empList"  [value]="emp.employeeId">{{emp.employeeNumber}} - {{emp.firstName}}</mat-option>
          </mat-select>
      </mat-form-field> -->
      <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
        <mat-label class="">Employee Name</mat-label> 
            <mat-select [(ngModel)]="employee"            

            placeholder="Select Employee Name" #select multiple (selectionChange)="isselectedemployesname($event)">
                <div class="select-all">
                    <mat-checkbox
                    [(ngModel)]="allSelected"

                    [ngModelOptions]="{standalone: true}"
                    type="checkbox"
                    (change)="toggleAllSelection()">
                    Select All</mat-checkbox>
                </div>
                <mat-option *ngFor="let emp of empList" [value]="emp.employeeId">
                  {{emp?.employeeNumber}}-{{emp?.user?.name}}
                                </mat-option>
            </mat-select>
    </mat-form-field> 
      <!-- <input 
      [(ngModel)]="employee"
            class="form-control email-input select-placeholder"
      placeholder="Select employee"
      [disabled]="!isClientSelected"
      [matAutocomplete]="auto"

      (keyup)="empSearch($event)"
      style="height: 50px;
      margin-top: 5px;
      font-size: 15px;"
      (selectionChange)="isselecteEmployee($event)"

    /> -->
    <!-- <mat-autocomplete #auto="matAutocomplete">
      <mat-option value="All" (click)="empAllSelect()"  >All</mat-option>
     
      <mat-option
        *ngFor="let emp of empList"
        [value]="emp.employeeId"
        (click)="empSelect(emp)"
      >
      {{emp?.employeeNumber}} - {{emp?.user?.name}}
    </mat-option>
      <mat-option
        [value]="null"
        *ngIf="employeeNotFound"
        (click)="noEmployeeFound()"
        >No Employee Found</mat-option
      >
    </mat-autocomplete> -->
  
    <!-- <span *ngIf="empolyeeapproverexist" class="span-error">
      employee Already Exist</span
    > -->
    
     
</div>
<div class="col-lg-2 col-md-2 col-sm-12">
  <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
      <mat-label>Choose Location</mat-label> 
      <mat-select placeholder="Select Location" [(ngModel)]="locationName" (selectionChange)="isselectLocation($event)"  >
      <mat-option [value]=undefined> -- Select Location -- </mat-option>
      <!-- <mat-option value="Chennai">Chennai</mat-option> -->
      <mat-option *ngFor="let location of locationList"  [value]="location.locationId">{{location.address}}</mat-option>
      </mat-select>
  </mat-form-field>
  <!-- [disabled]="!isClientSelected" -->
</div>
  <div class="col-lg-1 col-md-1 col-sm-12 float-end"></div>

    <!-- [disabled]="btndisable" -->
<table mat-table [dataSource]="dataSource" *ngIf="selectedEmployeeData?.length != 0  && tableShow" >
    
    <!-- Position Column -->
    <ng-container matColumnDef="sino">
      <th mat-header-cell *matHeaderCellDef> <b>SI.NO</b> </th>
      <td mat-cell *matCellDef="let element; let i =index">  {{ currentPageOffset + i + 1 }} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="employeeName" >
      <th mat-header-cell *matHeaderCellDef> <b>EMPLOYEE CODE/EMPLOYEE NAME</b> </th>
      <td mat-cell *matCellDef="let element">  <!-- Reference element for each row -->
        {{element.employeeNumber}} - {{element.user.name}}
      </td>
       <!-- <td mat-cell *matCellDef="let element">1108-USHA</td> -->

    </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef> <b>ACTION</b>  </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="text-center" (click)="deleteemployee(i)">
          <mat-icon style="    vertical-align: middle;
          color: red;
          position: relative;
          right: 94px;">delete</mat-icon>
        </div>
      </td>
    </ng-container>


    <!-- <ng-container matColumnDef="shift">
      <th mat-header-cell *matHeaderCellDef><b>SHIFT</b></th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill" style="margin-top: 5px;">
          <mat-select [(ngModel)]="element.selectedShift" style="padding: 6px;">
            <mat-option *ngFor="let shift of shifts" [value]="shift.shiftId">
              {{ shift.shiftName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container> -->
    
 

  

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4" align="center">
        <div class="alert alert-secondary" style="width: 100%;">
          <!-- <h3>No User List Found {{input.value}}</h3> -->
           <h3>No Shift Record Found</h3>
        </div>
      <td>
    </tr>
  </table>
  <button type="button" class="btn btn-primary btm-sm" (click)="submit()"  
  style="        position: relative;
  bottom: -12px;
  font-size: 14px;
  width: 62px;
  right: 95px;"[disabled]="submitdisable" >Submit</button>