<ng-container *ngIf="isLoading; else elseTemplate">
  <div class="loading">
    <app-loading></app-loading>
    <br>
    <h1>Please Wait Loading...</h1>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="container">
    <h2 mat-dialog-title>Create User</h2>
    <form action="" [formGroup]="userForm">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Employee Number</mat-label>
          <mat-icon matPrefix style="color:#6d6b6b;">person</mat-icon>
          <input matInput type="text" formControlName="EmpNo" placeholder="Enter employee number" [errorStateMatcher]="matcher"
            (keypress)="omit_special_char($event) && noNumber($event)">
          <mat-error *ngIf="f.EmpNo.hasError('required')">
            Please enter <strong> name</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <!-- name -->
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Name</mat-label>
          <mat-icon matPrefix style="color:#6d6b6b;">person</mat-icon>
          <input matInput type="text" formControlName="name" placeholder="Enter name" [errorStateMatcher]="matcher"
            (keypress)="omit_special_char($event) && keyPress($event)">
          <mat-error *ngIf="f.name.hasError('required')">
            Please enter <strong> name</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <!-- phone number -->
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Contact</mat-label>
          <mat-icon style="color: #6d6b6b;transform: translateY(0px);" matPrefix>phone</mat-icon>
          <input matInput type="text" placeholder="Enter contact number" maxlength="10" (keypress)="noNumber($event)"
            formControlName="phoneNumber" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="f.phoneNumber.hasError('required')">Please Enter <strong>Contact</strong> </mat-error>
          <mat-error *ngIf="f.phoneNumber.hasError('minlength')">Enter <strong>Valid Number</strong></mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Select Designation</mat-label>
          <mat-icon style="    color: #6d6b6b;
                      transform: translateY(0px);" matPrefix>account_box</mat-icon>
          <mat-select formControlName="designation" [errorStateMatcher]="matcher" >
            <ng-container *ngIf="designationlist.length > 0;else elsedesignation">
              <mat-option *ngFor="let u of designationlist " [value]="u.designationId">
                {{u.designationName}}
              </mat-option>
            </ng-container>
  
            <ng-template #elsedesignation>
              <mat-option>
                {{"No Designation List Found"}}
              </mat-option>
            </ng-template>
  
  
          </mat-select>
          <mat-error *ngIf="f.designation.hasError('required')">
            Please Select <strong>Designation</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <!-- mail id -->
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Mail-Id</mat-label>
          <mat-icon style="color: #6d6b6b;
                                transform: translateY(0px);" matPrefix>email</mat-icon>
          <input matInput type="text" formControlName="email" placeholder="ex@jesperapps.com"
            [errorStateMatcher]="matcher" />
          <mat-error *ngIf="f.email.hasError('pattern') && !f.email.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="f.email.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <!-- password -->
        <!-- <mat-form-field class="full-width" appearance="fill">
          <mat-label>Password</mat-label>
          <mat-icon style="color: #6d6b6b;
                                transform: translateY(0px);" matPrefix>lock</mat-icon>
          <input matInput type="text" formControlName="password" placeholder="****" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="f.password.hasError('required')">
            password is <strong>required</strong>
          </mat-error>
        </mat-form-field> -->
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Password</mat-label>
          <mat-icon matPrefix>lock</mat-icon>
          <input matInput 
                 [type]="hide ? 'password' : 'text'" 
                 formControlName="password" 
                 placeholder="Enter Password" 
                 minlength="8"
                 maxlength="12"
                 autocomplete="new-password" 
                 [errorStateMatcher]="matcher"/>
            <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          <mat-error *ngIf="f.password.hasError('pattern') || f.password.hasError('minlength')">
            Must contain uppercase, lowercase, number, special character. Minimum 8 characters.
          </mat-error>
      
          <mat-error *ngIf="f.password.hasError('maxlength')">
            Maximum 12 characters.
          </mat-error>
      
          <mat-error *ngIf="f.password.hasError('required')">
            <strong>Password is required</strong>
          </mat-error>
        
        </mat-form-field>
        <br>
        
        
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <!-- userType -->
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Select User Type</mat-label>
          <mat-icon style="    color: #6d6b6b;
                      transform: translateY(0px);" matPrefix>account_box</mat-icon>
          <mat-select formControlName="userType" [errorStateMatcher]="matcher" (selectionChange)="onUserTypeChange($event)">
            <ng-container *ngIf="userTypeList.length > 0;else elseUserType">
              <mat-option *ngFor="let u of userTypeList " [value]="u.userTypeId">
                {{u.userTypeName}}
              </mat-option>
            </ng-container>

            <ng-template #elseUserType>
              <mat-option>
                {{"No User List Found"}}
              </mat-option>
            </ng-template>


          </mat-select>
          <mat-error *ngIf="f.userType.hasError('required')">
            Please Select <strong>User Type</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="full-width" appearance="fill" *ngIf="selectedUserTypeId ==3">
          <mat-label>Select Buddy User</mat-label>
          <mat-icon style="    color: #6d6b6b;
                      transform: translateY(0px);" matPrefix>account_box</mat-icon>
          <mat-select formControlName="buddyuser" [errorStateMatcher]="matcher">
            <ng-container *ngIf="buddyuserList.length > 0;else elsebuddyUserType">
              <mat-option *ngFor="let u of buddyuserList " [value]="u.userId">
                {{u.name}}
              </mat-option>
            </ng-container>

            <ng-template #elsebuddyUserType>
              <mat-option>
                {{"No Buddy User List Found"}}
              </mat-option>
            </ng-template>


          </mat-select>
          <mat-error *ngIf="f.buddyuser.hasError('required')">
            Please Select <strong>Buddy User</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>


      <div class="row">
        <div class=" col-md-6">
          <label class="col-form-label">Profile Image</label>
          <input
            type="file"
            formControlName="attachment"
            (change)="getFileDetails($event)"
            accept=".png,.jpg,.jpeg"
            id="profile_image"
            class="form-control email-input select-placeholder"
          />
         
        </div>
        <div class="valid-msg" *ngIf="f.attachment.invalid && (f.attachment.touched || f.attachment.dirty)">
          <!-- If no file is selected -->
          <span *ngIf="f.attachment.errors?.required" class="text-danger">
            Please select a profile image.
          </span>
          <!-- If the file is not an image -->
          <span *ngIf="f.attachment.errors?.pattern" class="text-danger">
            Please upload a valid image file (.png, .jpg, .jpeg).
          </span>
        </div>
      </div>

      <div class="form-group mt-4" align="center">
        <button mat-stroked-button color="warn" style="width: 45%; margin: 0 0 0 8px;" type="button" mat-button
          mat-dialog-close>CANCEL</button>
        <button mat-stroked-button color="primary" style="width: 45%;margin: 0 0 0 8px;" type="submit"
          [disabled]="userForm.invalid" (click)="create_user()">
          CREATE
        </button>
      </div>
    </form>
  </div>
</ng-template>
