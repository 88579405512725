import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-organziation-list',
  templateUrl: './organziation-list.component.html',
  styleUrls: ['./organziation-list.component.scss']
})
export class OrganziationListComponent implements OnInit {

  constructor(private Gs: GlobalserviceService , private  router : Router) { }
  displayedColumns: string[] = ['organizationId', 'organizationName', 'contactEmail', 'contactPhoneNumber'];
  dataSource = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator:any;
  
  
  ngOnInit(): void {

    this.Gs.getOrganization().subscribe((a: any) => {
      this.dataSource = new MatTableDataSource(a);
      this.dataSource.paginator = this.paginator;
      console.log(a)
    })
  }




  editOrganization(a: any) {


    console.log(a); 
    
    this.router.navigate(['edit-org']);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
