
<div class="container">

    <div class="mat-elevation-z8">
  
      <div class="header_action">
       
        <button mat-flat-button>
          <span class="sign_up" [routerLink]="['/add-org']" > <mat-icon matPrefix style="
            transform: translateY(8px);">add</mat-icon>
            &nbsp; Create Organization 
          </span>
        </button>
        
  

        <mat-form-field appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="search name/ email/ phone number" #input>
        </mat-form-field>
      </div>
      <!-- <div class="filter">
  
      </div> -->
     
      <table mat-table [dataSource]="dataSource">
  
        <!-- Position Column -->
        <ng-container matColumnDef="organizationId">
          <th mat-header-cell *matHeaderCellDef> <b>Sl.No</b> </th>
          <td mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="organizationName">
          <th mat-header-cell *matHeaderCellDef> <b>NAME</b> </th>
          <td mat-cell *matCellDef="let element"> {{element.organizationName}} </td>
        </ng-container>
  
  
  
        <!-- Weight Column -->
        <ng-container matColumnDef="contactEmail">
          <th mat-header-cell *matHeaderCellDef> <b>CONTACT EMAIL</b> </th>
          <td mat-cell *matCellDef="let element"> {{element.contactEmail}} </td>
        </ng-container>
  
        <!-- Symbol Column -->
        <ng-container matColumnDef="contactPhoneNumber">
          <th mat-header-cell *matHeaderCellDef> <b>CONTACT NUMBER</b> </th>
          <td mat-cell *matCellDef="let element"> {{element.contactPhoneNumber}} </td>
        </ng-container>
       
  
        <!-- Symbol Column -->
        <!-- <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> <b>ACTION</b> </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="Example icon-button with a menu" (click)="editOrganization(element)" >
              <mat-icon>edit</mat-icon>
            </button> -->
            <!-- <mat-menu #menu="matMenu">
              <button mat-menu-item>
                <mat-icon>dialpad</mat-icon>
                <span>Redial</span>
              </button>
              <button mat-menu-item disabled>
                <mat-icon>voicemail</mat-icon>
                <span>Check voice mail</span>
              </button>
              <button mat-menu-item>
                <mat-icon>notifications_off</mat-icon>
                <span>Disable alerts</span>
              </button>
            </mat-menu> -->
          <!-- </td>
        </ng-container> -->
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4" align="center">
            <div class="alert alert-secondary" style="width: 120%;">
              <h3>No Organization List Found</h3>
            </div>
          <td>
        </tr>
      </table>
  
  
      <mat-paginator [pageSizeOptions]="[5, 10, 20,30,50]" showFirstLastButtons></mat-paginator>
  
    </div>
  </div>
  