<div class="container">
    <div class="mat-elevation-z8">
        <form class="form" [formGroup]="OTassignForm"> 
            <h2 class="pt-3">Assign OT</h2>
            <div class="row">
                <div class="col-md-3">

                    <mat-form-field class="example-full-width" appearance="outline" class="full-width">
                        <mat-label class="">Employee<span class="text-danger"> *</span></mat-label>
                        <input matInput formControlName="employee" 
                            placeholder="Select employee"
                            [matAutocomplete]="auto"  
                            (keyup)="empSearch($event)"
                            (click) ="clickfunction()"
                            #autoTrigger="matAutocompleteTrigger">
                        <mat-autocomplete #auto="matAutocomplete" class="custom-autocomplete">
                            <mat-option value="All" (click)="empAllSelect()" *ngIf ="userlist.length != 0">All</mat-option>
                            <mat-option *ngFor="let emp of userlist" [value]="emp.employeeId" (click)="empSelect(emp)">
                             {{emp.employee.employeeNumber}} - {{emp.name}}
                            </mat-option>
                            <mat-option
                                [value]="null"
                                *ngIf="employeeNotFound"
                                (click)="noEmployeeFound()">No Employee Found</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="validate.employee.invalid && submitted1 && selectedEmployeeData.length == 0">
                        Employee is required
                    </mat-error><br>
                    <span *ngIf="empolyeeOTexist" class="span-error"> Approver Already Exist</span>
                </div>
                
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>&nbsp;Date</mat-label>
                        <input matInput (click)="picker.open()" (dateChange)="someMethodName($event)" [errorStateMatcher]="matcher"
                          formControlName="date" [matDatepicker]="picker" placeholder="Select date" [min]="minDate"/>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker touchUi></mat-datepicker>
                        <!-- <mat-error *ngIf="(validate.date.hasError('required') && submitted1) || (validate.date.touched)">
                            Please select <strong>Date </strong>
                          </mat-error> -->
                    </mat-form-field>
                    <mat-error *ngIf="(validate.date.hasError('required') && submitted1)" style="position: relative;top:-10px">
                        Please choose <strong>Date </strong>
                    </mat-error>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>OT Hours</mat-label>
                        <input matInput type="text" placeholder="Enter OT Hours" maxlength="10" (keypress)="noNumber($event)"
                            style="padding: 0px 0 0 33px ;" formControlName="othours" />
                    </mat-form-field>
                    <mat-error *ngIf="validate.othours.hasError('required') && (submitted1 || validate.othours.touched)" style="position: relative;top:-10px">
                        Please enter <strong>OT Hours</strong>
                      </mat-error>
                      <mat-error *ngIf="validate.othours.hasError('pattern')" style="position: relative;top:-10px">
                        Please enter valid <strong>OT Hours</strong>
                      </mat-error>
                </div>
            </div>
            <div class="table-responsive"  *ngIf="selectedEmployeeData.length != 0 || OTassignId != null"
            style="border-top-left-radius: 10px; border-top-right-radius: 10px;">
                <table class="table  --table-bordered table-border-new" style="    width: 100%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                margin-top: 12px;">
                    <thead class="t-head">
                        <tr>  
                            <th class="text-center">Action</th>
                            <th>Employee Number</th>
                            <th>Employee Name</th>
                            <th>Phone number</th>
                            <th>Email Id</th>
                            <th>User Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of selectedEmployeeData; let i=index;">
                            <td>
                                <div class="text-center" (click)="deleteemployee(i)">
                                    <mat-icon style="vertical-align: middle;color: red;">delete</mat-icon>
                                </div>                       
                            </td>
                            <td>{{row.employee.employeeNumber}}</td>
                            <td>{{row.name}}</td>                                      
                            <td>{{row.phoneNumber}} </td>
                            <td>{{row.email}}</td>
                            <td>{{row.userType[0].userTypeName}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="row buttons">
                    <div class="col-md-12 text-right">
                            <button type="reset"  mat-raised-button  class="mr-2 btnCancel "(click)="clearemployeelist()" 
                            style="height: 35px;
                            position: relative;
                            right: 16px;">Clear</button>
                            <button type="button"  mat-raised-button  
                            style="background: #1a83ff;
                            color: #fff;
                            position: relative;
                            height: 33px;"(click)="submit()" [disabled]="buttondisable">Save</button>
                    </div>
                </div>
            </div>
            <!-- <div class="row pb-3" style="display: flex; justify-content: flex-end;">
                <div class="col-md-2 float-right">
                    <button mat-stroked-button  [routerLink]="['/assign-geofence']" color="warn" style="width: 100%;" type="button"
                  mat-dialog-close>Cancel</button>
               </div>
                <div class="col-md-2 pl-5  float-right">
                    <button mat-stroked-button color="warn" style="width: 100%;" type="submit" mat-button>
                    Assign OT</button>
                </div>
            </div> -->
        </form>
    </div>
</div>
  