<!-- <ng-container *ngIf="loading">
    <div class="loader">
        <app-loading></app-loading>
    </div>
 </ng-container> -->
  <mat-card  style="width: 90%; margin: auto;">
      <br>
      <mat-card-title>
        {{ geofenaceId ? 'Update Geofence' : 'Create Geofence' }}
      </mat-card-title>
      <br>
      <form action="" [formGroup]="geofenceForm" (ngSubmit)="GeofenceSubmit()">
          <div>
            <div class="row">
                <div class="form-group col-md-6">
                    <!-- <label>Geofence Name <span style="color:red"> *</span></label>
                    <input type="text"  id="geofencename"  formControlName="geofencename" class="form-control"  (keydown.enter)="$event.preventDefault()" placeholder="Enter GeofenceName"
                        autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search> -->
                        <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                            <mat-label>Geofence Name</mat-label>
                            <input matInput  formControlName="geofencename"  placeholder="Enter GeofenceName">
                            <mat-error *ngIf="(geofence.geofencename.invalid && geofence.geofencename.touched) || geofence.geofencename.dirty || submitted">
                                <span *ngIf="geofence.geofencename.errors?.required" class="text-danger">*Geofence name is required.</span>
                              </mat-error>
                        </mat-form-field>
                        <!-- <div *ngIf="(geofence.geofencename.invalid && geofence.geofencename.touched) || geofence.geofencename.dirty || submitted">
                            <div *ngIf="geofence.geofencename.errors?.required" class="text-danger">*Geofence name is required.</div>
                        </div> -->
                </div>
                <div class="form-group col-md-6">
                    <!-- <label>Select ClientName <span style="color:red"> *</span></label> -->
                    <!-- <select  formControlName="client" class="form-control"  placeholder="Select Client" (change)="selectclient($event)">
                       <option *ngFor="let client of clientList; let i=index;" value="{{client.clientId}}">{{client.clientName}}</option>
                    </select>  -->
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label>Select Client</mat-label> 
                        <mat-select placeholder="Select Client" formControlName="client" (selectionChange)="selectclient($event.value)">
                            <!-- <mat-option value="0"> -- Select Client -- </mat-option> -->
                            <mat-option *ngFor="let client of clientList; let i=index;"  [value]="client.clientId">{{client.clientName}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="(geofence.client.invalid && geofence.client.touched) || geofence.client.dirty || submitted">
                            <span *ngIf="geofence.client.errors?.required" class="text-danger">*Client is required.</span>
                          </mat-error>
                    </mat-form-field>
                    <!-- <div *ngIf="(geofence.client.invalid && geofence.client.touched) || geofence.client.dirty || submitted">
                        <div *ngIf="geofence.client.errors?.required" class="text-danger">*Client is required.</div>
                    </div>   -->
                </div>
            </div>
            
            <div class="row">
                <div class="row">
                    <div class="col-md-9">
                        <mat-label style="font-weight: bold;">Geofence Points</mat-label>
                    </div>
                    <div class="col-md-3 float-right pb-2">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Search</mat-label>
                            <mat-icon matPrefix>search</mat-icon>
                            <input matInput placeholder="Search Location" formControlName="searchInput">
                          </mat-form-field> -->
                        <input type="text"  id="address" class="form-control" (keydown.enter)="$event.preventDefault()" (keyup)="getaddress()" placeholder="Search Location"
                        autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                    </div>
                </div>
                <!-- <label>Geofence Points<span style="color:red"> *</span></label> -->
                <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" formControlName="geofencelocation"  [agmDrawingManager]="drawing">
                </agm-map> -->
                <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" formControlName="geofencelocation"  [agmDrawingManager]="drawing" (mapReady)="onMapReady($event)">
                </agm-map> -->
                <!-- <input type="text"  id="address" class="form-control" (keydown.enter)="$event.preventDefault()" (keyup)="getaddress()" placeholder="Enter  Location"
                autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search> -->
                <div *ngIf="geofenaceId; else elseBlock">
                    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" formControlName="geofencelocation"  (mapReady)="onMapReady($event)">
                        <div>
                            <agm-marker *ngFor="let location of clientLocations" [latitude]="location.latitude" [longitude]="location.longitude" 
                            [markerDraggable]="true"></agm-marker>
                        </div>
                    </agm-map>
                </div>
                <ng-template #elseBlock>
                    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" formControlName="geofencelocation"  [agmDrawingManager]="drawing">
                        <div *ngIf="markerStatus==true">
                            <agm-marker *ngFor="let location of clientLocations" [latitude]="location.latitude" [longitude]="location.longitude" 
                            [markerDraggable]="true"></agm-marker>
                        </div>
                    </agm-map>
                </ng-template>

                <agm-drawing-manager #drawing="agmDrawingManager" (polygonComplete)="polygonCreated($event)" [drawingControlOptions]="managerOptions.drawingControlOptions"
                    [polygonOptions]='managerOptions.polygonOptions' [drawingMode]='managerOptions.drawingMode'>
                </agm-drawing-manager>
                    <mat-error *ngIf="geofenceLoc">
                        *Geofence points is required.
                    </mat-error>
                <!-- <div *ngIf="(geofence.geofencelocation.invalid && geofence.geofencelocation.touched) || geofence.geofencelocation.dirty || submitted">
                    <div *ngIf="geofence.geofencelocation.errors?.required" class="text-danger">*Geofence points is required.</div>
                </div>   -->
            </div>
          </div>
          <div>
            <button  mat-stroked-button color="warn" style="width: 100%; margin: 20px 0 0 8px;" type="submit" mat-button>{{geofenaceId ? "Update Geofence":"Create Geofence"}}</button>
          </div>
      </form>
  </mat-card>