import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { MessagingService } from 'src/app/service/messaging.service';
import { MatDialog } from '@angular/material/dialog';
import { SettingviewComponent } from 'src/app/Components/settingview/settingview.component';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { ToastrService } from 'ngx-toastr';
import { isJSDocThisTag } from 'typescript';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-super-admin-home',
  templateUrl: './super-admin-home.component.html',
  styleUrls: ['./super-admin-home.component.scss']
})
export class SuperAdminHomeComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );
  organizationName: string = 'JesperApps'

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private auth: AuthService  , private messagingService: MessagingService , private dialog : MatDialog , private Gs: GlobalserviceService ,private angularFireMessaging: AngularFireMessaging  ) { }

  ngOnInit(): void {
  }

}
