import { Component, OnInit,Inject,ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-viewlocatioaddress',
  templateUrl: './viewlocatioaddress.component.html',
  styleUrls: ['./viewlocatioaddress.component.scss']
})
export class ViewlocatioaddressComponent implements OnInit {
  storeLocationDetails: any;
  dataSource = new MatTableDataSource;
  currentPageOffset: number = 0; 
  @ViewChild(MatPaginator) paginator:any;
  displayedColumns: string[] = ['sino','address'];
  constructor(public dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any) { }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  // }
  ngOnInit(): void {
    console.log("data",this.data);
    // this.dataSource=new MatTableDataSource(this.data);
    // this.dataSource.paginator = this.paginator;
    // console.log("data source:",this.dataSource);
    this.storeLocationDetails=this.data.locationDetails.address;
    console.log( " this.storeLocationDetails::",this.storeLocationDetails);
  }
  // onPageChange(event: any) {
  //   console.log("index",event.pageIndex);
  //   console.log("size",event.pageSize);
  //   this.currentPageOffset = event.pageIndex * event.pageSize;
  //   }

}
