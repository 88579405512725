<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport data-color="red"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
        <div class="img">
            <mat-nav-list>
                <mat-toolbar class="header">
                    <span>{{organizationName}}</span>
                </mat-toolbar>
                <hr style="border-top: 1px solid rgba(255, 250, 250, 0.45);">
                <a mat-list-item [routerLink]="['org-list']" [routerLinkActive]='["active"]' matTooltip="Organization">
                    <span class="material-icons ">analytics</span> <span> Manage Organization</span>
                </a>
                <a mat-list-item [routerLink]="['free-trial']" [routerLinkActive]='["active"]'
                    matTooltip="User Notification">
                    <span class="material-icons ">published_with_changes</span> <span data-bs-toggle="collapse"
                        href="#collapseExample" role="button" aria-expanded="false"
                        aria-controls="collapseExample">Manage Free Trial</span>
                </a>
            </mat-nav-list>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar>
            <mat-toolbar-row>
                <span class="example-spacer">
                    <button type="button" mat-mini-fab color="warn" aria-label="Toggle sidenav" class="primary"
                        mat-icon-button (click)="drawer.toggle()">
                        <mat-icon aria-label="Side nav toggle icon" color="warn">menu</mat-icon>
                    </button>
                </span>
                <span>
                    <h4 style="position: absolute;
              left: 64px;
              right: 0px;
              line-height: 0;">PRINTLOK</h4>
                </span>
            </mat-toolbar-row>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>