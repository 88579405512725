<!-- <div *ngFor="let a  of  viewLocationDetails[0].locationDetails">
   <span class="fw-bold mr-2 mt-2">* </span>   <span class="fw-bold mr-2">Alias Name:</span>{{a?.aliasName}} -<span class="fw-bold ml-2">Location:</span>  {{a?.address}}


</div> -->
<div *ngIf="dataSource.filteredData.length>0;else noData">
<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="sino">
        <th mat-header-cell *matHeaderCellDef class="text-center align-middle m-4"> <b class="mr-4">Sl.No</b> </th>
        <td mat-cell *matCellDef="let element; let i =index" class="text-center align-middle"> {{ currentPageOffset + i + 1 }}</td>
      </ng-container>

  
    <ng-container matColumnDef="aliasname" class=" align-middle">
        <th mat-header-cell *matHeaderCellDef class="pr-4 ml-4"> <!-- Added class here for spacing -->
          <b class="ml-4 align-middle text-center">Alias Name</b>
        </th>
        <td mat-cell *matCellDef="let element" class="border pl-4 align-middle"> <!-- Added class here for spacing -->
          {{element.aliasName}}
        </td>
      </ng-container>
      
      <ng-container matColumnDef="address" class="ml-4">
        <th mat-header-cell *matHeaderCellDef class="pr-4 align-middle text-center"> <!-- Added class here for spacing -->
          <b class="mr-4 ml-4 align-middle text-center">Address Name</b>
        </th>
        <td mat-cell *matCellDef="let element" class="border pl-4"> <!-- Added class here for spacing -->
          {{element.address}}
        </td>
      </ng-container>

<!--   
    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> <b>Address Name</b> </th>
        <td mat-cell *matCellDef="let element"> 
        <button (click)="passElement(element)">click</button>
    </td>
      </ng-container> -->



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <!-- <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4" align="center">
        <div class="alert alert-secondary" style="width: 128%;">
          <h3>No Location List Found </h3>
        </div>
      <td>
    </tr> -->
  </table>
  <div *ngIf="dataSource.filteredData.length>5">
    <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
  </div>
</div>
<ng-template #noData>
  <div class="p-3">
    <div class="no-data card text-center" style="text-align: center !important;
    background-color: #d44c39fa;
    border-radius: 5px;">
      <div class="card-body">No Location</div>
    </div>
  </div>
</ng-template>
  