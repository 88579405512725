
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

import { MapsAPILoader } from '@agm/core';
declare var google: any;

@Component({
  selector: 'app-client-location',
  templateUrl: './client-location.component.html',
  styleUrls: ['./client-location.component.scss']
})
export class ClientLocationComponent implements OnInit {

  @ViewChild('search') searchElementRef!: ElementRef;
  myModel = '';
  public mask = [/[0-9]/, /\d/, '-', /\d/, /\d/]
  
  AddLocationForm !: FormGroup
  title: string = 'AGM project';
  latitude: any;
  longitude: any;
  zoom: any;
  address: any;
  geocodedaddress :any;
  isLoading :any;
  map:any;
  Marker:any;
  loading:any= false;
  addressList:any[]=[];
submitted:boolean = false;
clientId:any;

  // Array to store multiple marker coordinates
  markers: Array<{ lat: number, lng: number, draggable: boolean }> = [
    { lat:12.7409232, lng:77.8252811, draggable: true },
    { lat: 0, lng: 0, draggable: true },
    { lat: 0, lng: 0, draggable: true },
  ];
  clientorgId: any;

  constructor(private fb: FormBuilder , private auth : AuthService , private gs : GlobalserviceService ,private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.AddLocationForm = this.fb.group({
      // AddressName :  ['', [Validators.required ]],
      clientcode:['',Validators.required],
      clientname:['',Validators.required],
      // address: this.fb.group(
      //   {
        
      //     addressName: [''],
      //     latitude: ['', Validators.required],
      //     longitude: ['', Validators.required],
      //   }
      // ),
      // status: this.fb.group({
      //   statusId: 1
      // })
    })
    this.route.paramMap.subscribe(params => {
      this.clientId = params.get('id'); // Assuming the parameter is named 'id'
      console.log('Client ID:', this.clientId);
    });
  

    if ( this.clientId) {
      this.gs.getClientbyClientId( this.clientId).subscribe((res:any)=>{
        console.log('res',res);


        this.AddLocationForm.patchValue({
          clientcode:res.clientCode,
          clientname:res.clientName
        })
        this.clientorgId= res.organization.organizationId;
    })
    }

   
       this.isLoading = false;
  

    this.setCurrentLocation();
   // this.addLocation();
    
  }

  get f() {
    return this.AddLocationForm.controls;
  }


  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }


  onMapReady(map: any) {
    alert("map ready");
    console.log(map);
    this.map = map;
   
       

  }

  getaddress()
  {
        let address = document.getElementById("address");

        const options = {
          fields: ["formatted_address", "geometry", "name"],
          strictBounds: false,
          types: ["establishment"],
        };
      
       // map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);
     const autocomplete = new google.maps.places.Autocomplete(address);
   //     const autocomplete = autocompleteapi(address, options);
     //   console.log(autocomplete);

        autocomplete.addListener("place_changed", ()=>{
               this.loading = true;
            let place = google.maps.places.PlaceResult = autocomplete.getPlace();

            if(place.geometry === undefined || place.geometry === null) {
              return;
            }
            let lat = place.geometry.location.lat();
          let lng = place.geometry.location.lng();

        // const mark = new google.maps.LatLng(lat,lng);

          this.latitude = lat
          this.longitude = lng
          this.loading = false;
        console.log(lat,lng);
        })
  }


  addLocation() {
    console.log(this.AddLocationForm.value)
    let x = this.AddLocationForm.value
       console.log('AddLocationForm.value',this.AddLocationForm.value);
       

    if (this.AddLocationForm.invalid) {
      this.submitted = true;
      return
    }
   
    // alert("form submitted");
     
     let user = this.auth.getLocalStorageData()
     let locationdata;
        console.log(user);

          // if(user.organization.organizationId != null 
          //   || user.organization.organizationId != undefined)
          //   {
              
          //   }else {
          //      locationdata = {

          //       latitude : x.address.latitude,
          //       longitude: x.address.longitude,
          //       address: x.AddressName, 
          //       user:{
          //         userId: user.userId
          //       } 
          //     };
          //   }
          locationdata = {

                
            clientName:this.AddLocationForm.value.clientname,
            clientCode:this.AddLocationForm.value.clientcode,
            organization:{
                organizationId:user.organization.organizationId
            
        }
           
        };

       let updateData = {
          clientId: this.clientId,
          clientName: this.AddLocationForm.value.clientname,
          clientCode: this.AddLocationForm.value.clientcode,
          organization: {
            organizationId: this.clientorgId
          }
        }

          console.log("locationdata");
          console.log(locationdata);

          this.isLoading = true

          if ( this.clientId) {

            this.gs.updateClient(this.clientId,updateData).subscribe((a: any) => {
            


              if (a.statusCode == 409) {
                Swal.fire(
                 a.message,
                  "",
                  'warning'
                )
              }
  
              
              if (a.statusCode == 200) {
                Swal.fire(
                 a.message,
                  "",
                  'success'
                )
                this.router.navigate(['/client-location']);
              }
             
              this.isLoading = false
  
              console.log(a)
            },
            err => {
              console.log
              this.isLoading = false
           
              Swal.fire(
                err.error.message,
                "",
                'error'
              )
            })
          }else{
            this.gs.createClient(locationdata).subscribe((a: any) => {
            


              if (a.statusCode == 409) {
                Swal.fire(
                 a.message,
                  "",
                  'warning'
                )
              }
  
              
              if (a.statusCode == 200) {
                Swal.fire(
                 a.message,
                  "",
                  'success'
                )
                this.router.navigate(['/client-location']);
              }
             
              this.isLoading = false
  
              console.log(a)
            },
            err => {
              console.log
              this.isLoading = false
           
              Swal.fire(
                err.error.message,
                "",
                'error'
              )
            })
          }
        

  }

  markerDragEnd(event: any,index) {
    console.log('event',event.coords);

    const updatedLat = event.coords.lat;
    const updatedLng = event.coords.lng;
    // this.markers[index].lat = updatedLat;
    // this.markers[index].lng = updatedLng;
    this.markers.push({lat:updatedLat,lng:updatedLng,draggable:true});

    console.log('markers',this.markers);
    
    this.latitude = event.coords.lat
    this.longitude =event.coords.lng

    let geocoder = new google.maps.Geocoder;
    let latlng = {lat: this.latitude, lng: this.longitude};
    let that = this;
  

      geocoder
    .geocode({ location: latlng })
    .then((response :any) => {
     
      console.log(response.results[0].formatted_address);
      this.addressList.push(response.results[0].formatted_address)
      this.addressList.forEach((address,index) => {
        console.error('marker:', address);
        if (typeof address.lat !== 'number' || typeof address.lng !== 'number') {
          console.error('Invalid lat or lng:', address);
        }
  
       this.AddLocationForm.patchValue({
        AddressName : address
       })
      });
      this.AddLocationForm.get('address')?.patchValue({
        latitude: event.coords.lat,
        longitude:event.coords.lng,
       
  
      })
    })

  
  
            console.log(this.AddLocationForm.value)
           //alert("address" + this.AddLocationForm.get('address')?.invalid);
  
  }
  mapClicked(c: any) {
    console.log(c)
    let a = c.coords
    this.latitude = a.lat
    this.longitude = a.lng


    let x = this.AddLocationForm.get('address')?.patchValue({
      latitude: a.lat,
      longitude: a.lng,


    })
    console.log(x)
  }


}
