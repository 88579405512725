import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
// import { ViewTrackUserListComponent } from '../view-track-user-list/view-track-user-list.component';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
// import { UserLocationComponent  } from '../user-location/user-location.component';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { forkJoin } from 'rxjs';

//new
import { ErrorStateMatcher } from '@angular/material/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-ot-assign',
  templateUrl: './ot-assign.component.html',
  styleUrls: ['./ot-assign.component.scss']
})
export class OtAssignComponent implements OnInit {
  OTassignForm: FormGroup;
  userlist: any = [];
  geoList: any = [];
  submitted1: boolean = false;
  isDisabled = true;
  allSelected = false;
  totalData: any = [];
  selectedUsersControl = new FormControl();
  @ViewChild('select') select: MatSelect;
  type: any;
  user: any;
  geofence: any;
  userId: any;

  //new
  client: any;
  clientlist: any = [];
  employee: any;
  empList: any = [];
  matcher = new MyErrorStateMatcher();
  min: any;
  endDate_disable: boolean = true;
  private unsubscribe$: Subject<void> = new Subject<void>();
  employeelistsub: any;
  employeeId: any;
  selectedEmployeeData: any = [];
  selectedEmployeeData1:any = [];
  p: number = 1;
  pagePerItem: any = 10;
  OTassignId: any;
  empolyeeOTobject: any;
  empolyeeOTexist: boolean;
  employeeNotFound: boolean = false;
  readonlyDisable: boolean = false;
  disableFeildforAll: boolean= true;
  empolyeereadonly: boolean= true;
  organzationId: any;
  loader: boolean;
  postdatamultiple: any=[];
  userNameList:any =[];
  minDate: Date;
  userdata: any;
  buttondisable = false;
  @ViewChild(MatAutocompleteTrigger) autoTrigger!: MatAutocompleteTrigger;
  constructor(private gs: GlobalserviceService,
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.minDate = new Date();
    let userdata = this.auth.getLocalStorageData();
    this.userdata = this.auth.getLocalStorageData();
    console.log(userdata);
    this.userId = userdata.userId;
    console.log(this.userId);
    let id = this.auth.getLocal_Org().organizationId
    this.organzationId = this.auth.getLocal_Org().organizationId
    console.log(userdata.userId)
    this.OTassignForm = this.fb.group({
      // client: ['', [Validators.required]],
      employee: ['',],
      date: ['', [Validators.required]],
      othours: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]
    })
    this.empolyeereadonly = true; // Ensure this is initialized correctly
    this.disableFeildforAll = true; // Set this value properly
    
    // if (this.auth.getLocal_Org()?.organizationId) {
    //   let organizationId = this.auth.getLocal_Org().organizationId;
    //   this.gs.getclientByOrg(organizationId).subscribe((data: any) => {
    //     this.clientlist = data;
    //     console.log("clientlist", this.clientlist);
    //   })
    // }
    if (this.auth.getLocal_Org()?.organizationId) {
      let id = this.auth.getLocal_Org().organizationId;
      console.log(userdata.userId)
      this.gs.get_user_org(id).subscribe((a: any) => {
         console.log(a);
         this.userNameList=a;
         console.log(this.userNameList);
        a.map(
          (e: any) => {
            if (userdata.userType[0].userTypeName == "BUDDY USER") {
              if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
                && e.buddyUser?.userId == userdata.userId) {
                console.log(e);
                this.userlist.push(e);
              }
            }
            else {
              if (e.userId != userdata.userId) {
                console.log(e)
                this.userlist.push(e);
              }
            }
          }
        )
      })
    } else {

      let id = this.auth.getLocalStorageData()?.userId;
      this.gs.get_primary_users_user(id).subscribe((a: any) => {
        a.map(
          (e: any) => {
            if (e.userId != id) {
              this.userlist.push(e);
            }
          }
        )
      })
    }
  }

  someMethodName(date: any) {
    this.min = new Date(date.value)
    this.endDate_disable = false;
    this.submitted1 = false;
  }

  toggleAllSelection() {
    if (this.allSelected) {
      console.log("select");
      this.select.options.forEach((item: MatOption) => item.select());
      //  this.add();
    }
    else {
      console.log("unselect");
      this.isDisabled = true;
    }
  }
  assigntype(event: any) {
    console.log(event);
    this.type = event.value;
    console.log(this.type);
  }
  get validate() { return this.OTassignForm.controls; }

  // clientselect(event: any) {
  //   console.log("client", event.value);
  //   this.client = event.value;
  //   console.log(this.client);
  //   this.empList.length = 0;
  //   this.OTassignForm.value.employee = null;
  //   this.OTassignForm.patchValue({
  //     employee: null
  //   })
  //   if (this.OTassignForm.value.client != null && this.OTassignForm.value.client != "null") {
  //     this.empolyeereadonly = false;
  //     this.disableFeildforAll = false;
  //     this.gs.get_employee_byClient_list(this.OTassignForm.value.client).subscribe(
  //       (ClientEmployees: any) => {
  //         console.log(ClientEmployees);
  //         this.empList = [];
  //         if (ClientEmployees.length != undefined) {
  //           this.empList = ClientEmployees;
  //         }
  //       });
  //   }
  // }
  pageChages() {
    this.p = 1;
  }
  deleteemployee(index) {
    if (index != -1) {
      this.selectedEmployeeData.splice(index, 1);
    }
  }
  clearemployeelist() {
    this.selectedEmployeeData = [];
  }

  empSearch(event) {
    console.log("employee field clicked");
    // this.empolyeereadonly=false;
    this.postdatamultiple = [];
    this.employeeNotFound = false; // Reset the no employee flag
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    let userdata = this.auth.getLocalStorageData();
    // Employee List API
    if (employeenumber.length >= 2) {
      if (this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
      // if (this.OTassignForm.value.client != null && this.OTassignForm.value.client !== "null") {
        this.employeelistsub = this.gs.getemployeeseachbyEmpNoKeyword(employeenumber,this.organzationId)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            switchMap(() => this.gs.getemployeeseachbyEmpNoKeyword(employeenumber,this.organzationId))
          )
          .subscribe((result: any) => {
            console.log("result", result);
            this.userlist = [];

            if (result.statusCode) {
              console.log(result.statusCode);
              this.userlist = [];
              this.employeeNotFound = true; // Set the flag if status code indicates no employees
            } else {
              // this.userlist = result;
              result.map(
                (e: any) => {
                  if (userdata.userType[0].userTypeName == "BUDDY USER") {
                    if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
                      && e.buddyUser?.userId == userdata.userId) {
                      console.log(e);
                      this.userlist.push(e);
                    }
                  }
                  else {
                    if (e.userId != userdata.userId) {
                      console.log(e)
                      this.userlist.push(e);
                    }
                  }
                }
              )

              if (this.userlist.length === 0) {
                this.employeeNotFound = true; // Set the flag if the list is empty
              }
            }


          },
          (error:any)=>{
            console.log(error.statusCode);
            this.userlist = [];
            this.employeeNotFound = true;
          }
        )
      // }
    }
    else{
      if (this.auth.getLocal_Org()?.organizationId) {
        let id = this.auth.getLocal_Org().organizationId;
        console.log(userdata.userId)
        this.gs.get_user_org(id).subscribe((a: any) => {
           console.log(a);
           this.userNameList=a;
           console.log(this.userNameList);
           this.userlist = [];
          a.map(
            (e: any) => {
              if (userdata.userType[0].userTypeName == "BUDDY USER") {
                if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
                  && e.buddyUser?.userId == userdata.userId) {
                  console.log(e);
                  this.userlist.push(e);
                }
              }
              else {
                if (e.userId != userdata.userId) {
                  console.log(e)
                  this.userlist.push(e);
                }
              }
            }
          )
        })
      } else {
  
        let id = this.auth.getLocalStorageData()?.userId;
        this.gs.get_primary_users_user(id).subscribe((a: any) => {
          a.map(
            (e: any) => {
              if (e.userId != id) {
                this.userlist.push(e);
              }
            }
          )
        })
      }
    }
  }

  disableEnter(event: Event) {
    if ((event as KeyboardEvent).key === 'Enter') {
      event.preventDefault();
    }
  }
  clearEmpArray() {
    this.empList = []
  }
  noEmployeeFound() {
    this.employeeNotFound = false;
    this.employeeId = undefined;
    this.employee = undefined;
    this.employeeNotFound = false
  }

  empSelect(emp) {
    this.postdatamultiple = [];
    this.disableFeildforAll = false;
    this.empolyeereadonly = false;
    this.readonlyDisable = false;
    console.log(emp);

    this.employeeId = emp.userId;
    this.employee = emp.name;
    console.log(this.employee);
    if (this.OTassignId) {
      console.log("if");
      this.OTassignForm.patchValue({
        employee: this.employee
      });
    } else {
      console.log("else");
      this.OTassignForm.patchValue({
        employee: null
      });
      // console.log("this.selectedEmployeeData", this.selectedEmployeeData);

      let hasemployee = this.selectedEmployeeData.some(e => e.userId == emp.userId);
      if (!hasemployee) {
        this.selectedEmployeeData.push(emp);
        console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
      }
      console.log(" this.selectedEmployeeData", this.selectedEmployeeData);
    }
    if (this.selectedEmployeeData.length >= 1) {
      this.OTassignForm.get('employee')?.clearValidators();
    } else {
      this.OTassignForm.get('employee')?.setValidators([Validators.required]);
    }
    this.OTassignForm.get('employee')?.updateValueAndValidity();

  }
  clickfunction(){
    console.log("clickfunction",this.userlist);
    this.userlist =  this.userlist;
    this.autoTrigger.openPanel();
  }
  empAllSelect() {
    if (this.OTassignForm.value.employee === "All") {
      // Disable the field immediately after selecting "All"
      this.disableFeildforAll = true;
      this.empolyeereadonly = true;
      this.readonlyDisable = true;

      this.loader = true;
      // if (this.OTassignForm.value.client != null && this.OTassignForm.value.client !== "null") {
        let id = this.auth.getLocal_Org().organizationId;
        this.gs.get_user_org(id).subscribe((a: any) => {
           console.log(a);
            if (a.statusCode) {
              // Handle error response
              console.error("Error response:", a);
              this.selectedEmployeeData =[];
              this.employeeNotFound =true;
            } else {
              console.log("else");
              this.OTassignForm.patchValue({
                employee: null
              });
              this.userlist =[];
              // this.userlist=a;
              console.log("this.empList", this.empList);
              console.log("this.selectedEmployeeData", this.selectedEmployeeData);
              a.map(
                (e: any) => {
                  if (this.userdata.userType[0].userTypeName == "BUDDY USER") {
                    if (e.userId != this.userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && this.userdata.userType[0].userTypeName == "BUDDY USER")
                      && e.buddyUser?.userId == this.userdata.userId) {
                      console.log(e);
                      this.userlist.push(e);
                    }
                  }
                  else {
                    if (e.userId != this.userdata.userId) {
                      console.log(e)
                      this.userlist.push(e);
                    }
                  }
                }
              )
              console.log("this.userlist",this.userlist);
              console.log("this.selectedEmployeeData1", this.selectedEmployeeData);
              this.userlist.forEach(element => {
                let hasemployee = this.selectedEmployeeData.some(e => e.userId === element.userId);
                if (!hasemployee) {
                  this.selectedEmployeeData.push(element);
                }
              });

              console.log("this.selectedEmployeeData", this.selectedEmployeeData);

            }
            // Enable the field after processing the response
            this.disableFeildforAll = false;
            this.empolyeereadonly = false;
            this.loader = false;
            this.readonlyDisable = false;

          }, error => {
            // Handle error during the HTTP call
            console.error("Error fetching employees:", error);
            this.disableFeildforAll = false;
            this.empolyeereadonly = false;
            this.readonlyDisable = false;
            this.loader = false;
          });
      // }
    }
  }


  submit() {
    console.log("form::", this.OTassignForm.value);

    this.submitted1 = true;
    console.log(this.OTassignForm.invalid);
    // console.log(this.empolyeeapproverexist ==true);
    console.log(this.selectedEmployeeData.length == 0);
    console.log(this.selectedEmployeeData.length == 0 || this.OTassignForm.invalid);
    if (this.selectedEmployeeData.length == 0 || this.OTassignForm.invalid) {
      return;
    }

    let proappform = this.OTassignForm.value;
    console.log(proappform);
    this.buttondisable = true;
    if(this.OTassignId)
    {         
      console.log("update form");
      console.log(proappform);   
      // var employeeapproverupdate  = 
      // {          
      //   empolyeeapproverId : this.OTassignId,
      //   employeeApproverStatus: this.data.employeeApproverStatus.listTypeValueId,
      //   employee: {
      //   employeeId: proappform.employee
      //   },
      //   approvers: {
      //   approverId:  proappform.approver
      //   },
      //   organization: {
      //     organizationId: this.organzationId
      //   },  
      //   approverTypeId : proappform.approverType,
      //   status : {
      //     listTypeValueId: this.data.status.listTypeValueId
      //   }

      // }

      // console.log(employeeapproverupdate);
      // this.gs.updateEmployeeApprover(employeeapproverupdate , this.data.empolyeeapproverId)
      // .subscribe((a:any )=> 
      // {
      //   this.loader = false;
      //   this.success = true;
      //   this.success_msg = a.message;
      //   setTimeout(() => {
      //     this.success  = false;
      //     this.router.navigate(['/home/manage-emp-approver-list']);
      //   }, redirectMsgTimeOut);
      // },
      // err =>{
      //   this.btnDisable = false;
      //   this.loader = false;
      //   this.error = true;
      //   this.error_msg = err.error.message;
      //   setTimeout(() => {
      //     this.error = false;
      //   }, redirectMsgTimeOut);
      // })
    }
    else
    {
      // this.loader=true;
      // console.log("this.selectedEmployeeData", this.selectedEmployeeData);
      // this.postdatamultiple = []; 
      // this.selectedEmployeeData.forEach((element: any) => {
      //   console.log("element::",element);
      //   this.gs.getshiftemployeeassignbyEmp(element.employee.employeeId).subscribe(
      //     (a:any) => {
      //       console.log("getshiftemployeeassignbyEmp",a);
      //       console.log(a.statusCode);
      //       if(a.statusCode != undefined){
      //         Swal.fire(
      //           `Please assign Shift to Employee Number ${element.employee.employeeNumber}!`,
      //           "",
      //           'error'
      //         )
      //       }
      //       else{
           
      //         let shiftEmployeeAssignId = a.shiftEmployeeAssignId;
      //         console.log("else", shiftEmployeeAssignId);
      //           let employeeOTAssign = {
      //             otHours: proappform.othours,
      //             otDate: this.datePipe.transform(proappform.date, 'yyyy-MM-dd'),
      //             employee: {
      //               employeeId: element.employee.employeeId
      //             },
      //             shiftEmployeeAssign: {
      //               shiftEmployeeAssignId: shiftEmployeeAssignId
      //             },
      //             assignedBy: {
      //               userId: this.userId
      //             }
      //           }
      //           this.postdatamultiple.push(employeeOTAssign);
      //         }

      //     })
      // });
      // console.log(this.postdatamultiple);



        // console.log("this.selectedEmployeeData", this.selectedEmployeeData);
        // this.postdatamultiple = []; 
        // let requests: any[] = [];

        // // Collect all the observables into requests array
        // this.selectedEmployeeData.forEach((element: any) => {
        //   requests.push(this.gs.getshiftemployeeassignbydateandEmp(element.employee.employeeId,this.datePipe.transform(proappform.date, 'yyyy-MM-dd')));
        // });

        // // Use forkJoin to wait for all requests to complete
        // forkJoin(requests).subscribe((responses: any[]) => {
        //   responses.forEach((a, index) => {
        //     console.log(index);
        //     const element = this.selectedEmployeeData[index];
        //     console.log(element);
        //     console.log("getshiftemployeeassignbyEmp", a);
        //     console.log(a.statusCode);

        //     // Check the response and push to postdatamultiple if valid
        //     if (a.statusCode !== undefined) {
        //       Swal.fire(
        //         `Please assign Shift to Employee Number ${element.employee.employeeNumber}!`,
        //         "",
        //         'error'
        //       );
        //       return
        //     }
        //     console.log("else");
        //       let shiftEmployeeAssignId = a.shiftEmployeeAssignId;
        //       console.log("else", shiftEmployeeAssignId);
        //       let employeeOTAssign = {
        //         otHours: proappform.othours,
        //         otDate: this.datePipe.transform(proappform.date, 'yyyy-MM-dd'),
        //         employee: {
        //           employeeId: element.employee.employeeId
        //         },
        //         shiftEmployeeAssign: {
        //           shiftEmployeeAssignId: shiftEmployeeAssignId
        //         },
        //         assignedBy: {
        //           userId: this.userId
        //         }
        //       };
        //       this.postdatamultiple.push(employeeOTAssign);  // Push to postdatamultiple
        //   });
        //   console.log("Final postdatamultiple array:", this.postdatamultiple);
        //   return

        //         // this.btnDisable = true;
        //         if(this.postdatamultiple.length !=0){
        //           // return
        //           this.gs.AssignEmployeeOT(this.postdatamultiple).subscribe(
        //           (a:any) => {
        //             // this.loader = false;
        //             // this.success = true;
        //             // this.success_msg = a.message;
        //             // this.btnDisable = false;
        //             Swal.fire(
        //               "OT Assigned Successfully",
        //               "",
        //               'success'
        //             )
        //             setTimeout(() => {
        //               // this.success  = false;
        //               this.router.navigate(['/OT-assign-list']);
        //             });
        //           },
        //           (err) =>{
        //             console.log(err);
        //             // this.btnDisable = false;
        //             // this.loader = false;
        //             // this.error = true;
        //             // this.error_msg = err.error.message;
        //             // setTimeout(() => {
        //             //   this.error = false;
        //             // }, redirectMsgTimeOut);
        //             Swal.fire(
        //               err.error.message,
        //               "",
        //               'error'
        //             )
        //           })  
        //         } 
        // });        
    
        console.log("this.selectedEmployeeData", this.selectedEmployeeData);
        this.postdatamultiple = [];
        let requests: any[] = [];
        let hasError = false; // Flag to track if there's any error
        let errorEmployees: string[] = []; 
        
        // Collect all the observables into requests array
        this.selectedEmployeeData.forEach((element: any) => {
          requests.push(this.gs.getshiftemployeeassignbydateandEmp(element.employee.employeeId, this.datePipe.transform(proappform.date, 'yyyy-MM-dd')));
        });
        
        // Use forkJoin to wait for all requests to complete
        forkJoin(requests).subscribe((responses: any[]) => {
          responses.forEach((a, index) => {
            console.log(index);
            const element = this.selectedEmployeeData[index];
            console.log(element);
            console.log("getshiftemployeeassignbyEmp", a);
            console.log(a.statusCode);
        
            // Check the response and if there's an error, show a message and set hasError to true
            if (a.statusCode !== undefined) {
              errorEmployees.push(element.employee.employeeNumber);
              Swal.fire(
                `Please assign Shift to Employee Number ${element.employee.employeeNumber}!`,
                "",
                'error'
              );
              hasError = true; // Set the flag to true since there's an error
              return; // Skip pushing to postdatamultiple if there's an error
            }
        
            console.log("else");
            let shiftEmployeeAssignId = a.shiftEmployeeAssignId;
            console.log("else", shiftEmployeeAssignId);
            let employeeOTAssign = {
              otHours: proappform.othours,
              otDate: this.datePipe.transform(proappform.date, 'yyyy-MM-dd'),
              employee: {
                employeeId: element.employee.employeeId
              },
              shiftEmployeeAssign: {
                shiftEmployeeAssignId: shiftEmployeeAssignId
              },
              assignedBy: {
                userId: this.userId
              }
            };
            this.postdatamultiple.push(employeeOTAssign);  // Push to postdatamultiple if no error
          });
        
          console.log("Final postdatamultiple array:", this.postdatamultiple);
        
          // If there's any error, do not proceed with API call
          if (hasError) {
            console.log("Error occurred, skipping API call");
            if (errorEmployees.length > 0) {
              Swal.fire(
                `Please assign Shift to the following Employees No: ${errorEmployees.join(', ')}`,
                "",
                'error'
              );
              return; // Skip the API call if there are errors
            }
            return;
          }
          console.log(hasError);
          // return;
          console.log(hasError);
          // If no errors, call the API to assign OT
          if (this.postdatamultiple.length != 0) {
            this.gs.AssignEmployeeOT(this.postdatamultiple).subscribe(
              (a: any) => {
                console.log("response",a);
                if (a.statusCode === 200) {
                  Swal.fire(
                    "OT Shifts Assigned Successfully",
                    "",
                    'success'
                  );
                }
                if (Array.isArray(a)) {
                  const errorMessages = a
                      .filter((item: any) => item.statusCode === 409) // Filter responses with statusCode 409
                      .map((item: any) => item.description); // Get the error description
          
                  if (errorMessages.length > 0) { 
                      Swal.fire({
                          html: `<div style="max-height: 400px; overflow-y: auto;">${errorMessages.join('<br/>')}</div>`, // Display all error messages
                          icon: 'warning',
                          width:800
          
                      });
                    }
                  }
                setTimeout(() => {
                  this.router.navigate(['/OT-assign-list']);
                });
              },
              (err) => {
                console.log(err);
                Swal.fire(
                  err.error.message,
                  "",
                  'error'
                );
              }
            );
          }
        });
        
      }
  }
  // employeeapprovervalidate(){
  //   console.log("project : " +  this.OTassignForm.value.employee);    
  //   if(!this.data)
  //   {
  //     if(this.OTassignForm.value.employee != 0)
  //     {           
  //       this.gs.getEmployeeApproversvalidate(this.OTassignForm.value.employee).subscribe(a => 
  //       {
  //         console.log(a);
  //         this.empolyeeOTobject = a;
  //         if(this.empolyeeOTobject.isexist)
  //         {
  //           console.log("employee name isexist");
  //           this.empolyeeOTexist = true;
  //         }
  //         else{
  //           this.empolyeeOTexist = false;
  //         }
  //       })
  //     }
  //     else{
  //       this.empolyeeOTexist = false;
  //     }
  //   }   
  // }
  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}

