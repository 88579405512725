<!-- <div>
    <ul style="list-style-type: none;">
      <li class="m-3" *ngFor="let employee of data?.employees; let i = index">
        <div *ngIf="employee?.employeeId">
          <span class="mr-3 font-weight-bold fs-4">*</span>
          {{ employee?.employeeNumber }} - {{employee.user?.name}}
        </div>
       
      </li>
    </ul>
  </div> -->
 <div *ngIf="dataSource.filteredData.length>0;else noEmp">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="sino">
        <th mat-header-cell *matHeaderCellDef class="text-center"> <b>Sl.No</b> </th>
        <td mat-cell *matCellDef="let element; let i =index" class="text-center"> {{ currentPageOffset + i + 1 }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="empNumbers" >
        <th mat-header-cell *matHeaderCellDef> <b>Employee Number</b> </th>
        <td mat-cell *matCellDef="let element"> {{element?.employeeNumber }} </td>
      </ng-container>



      <!-- Weight Column -->
      <ng-container matColumnDef="employeeName" >
        <th mat-header-cell *matHeaderCellDef> <b>Employee Name</b> </th>
        <td mat-cell *matCellDef="let element"> {{element?.user?.name}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> 
  </table>
  <div *ngIf="dataSource.filteredData.length>5">
    <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5,10]" showFirstLastButtons></mat-paginator>
  </div>
</div>
<ng-template #noEmp>
  <div class="p-3">
    <div class="no-data card text-center" style="text-align: center !important;
    background-color: #d44c39fa;
    border-radius: 5px;">
      <div class="card-body">No Employee</div>
   </div>
  </div>
</ng-template>

  
  