<div class="container">
    <div class="mat-elevation-z8">
        <form [formGroup]="assignForm"  (ngSubmit)="onSubmit()"> 
            <h2 class="pl-5 pt-3">Assign Client Employees </h2>
            
           
        <div>
            <div class="row pl-5"  >
                <div class="col-md-4">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label>Choose Client </mat-label> 
                        <mat-select placeholder="Select Client" formControlName="client"  (selectionChange)="isselecteduser($event)">
                            <mat-option [value]=undefined> -- Select Client -- </mat-option>
                            <!-- <mat-option *ngFor="let users of clientList"  [value]="users.clientId">{{users.clientCode}}-{{users.clientName}}</mat-option> -->
                            <ng-container *ngIf="setBoolean">
                                <mat-option *ngFor="let users of clientList" [value]="users.clientId">
                                  {{users.clientCode}} - {{users.clientName}}
                                </mat-option>
                              </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <div class="valid-msg" style="position: relative; top: -15px;" *ngIf="(validate.client?.invalid && validate.client?.touched) || validate.user?.dirty || submitted">
                        <span *ngIf="validate.client.errors?.required" class="text-danger">Please select Client</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                        <mat-label class="">Choose Employee<span class="text-danger"> *</span></mat-label> 
                            <mat-select   #select (selectionChange)="isselectedname($event)"formControlName="geofence"
                            multiple
                            placeholder="Select Employee Name" >
                                <div class="select-all">
                                    <mat-checkbox
                                    [(ngModel)]="allSelected"
                                    [ngModelOptions]="{standalone: true}"
                                    type="checkbox"
                                    (change)="toggleAllSelection()">
                                    Select All</mat-checkbox>
                                </div>
                                    <mat-option 
                                    (click)="individualCheckboxChanged()"
                                    *ngFor="let geofence of empList"  [value]="geofence.employee?.employeeId">
                                    {{geofence.name}}
                                </mat-option>
                            </mat-select>


                            <!-- <input 
                            [(ngModel)]="employee"
                                  class="form-control email-input select-placeholder"
                            placeholder="Select employee"
                            [matAutocomplete]="auto"
                            
                            (keyup)="empSearch($event)"
                            style="height: 50px;
                            margin-top: 5px;
                            font-size: 15px;"
                          /> -->
                          <!-- (click)="clearEmpArray()" -->
                          <!-- <mat-autocomplete #auto="matAutocomplete"> -->
                            <!-- <mat-option value="All" (click)="empAllSelect()"  >All</mat-option> -->
                           
                            <!-- <mat-option
                            *ngFor="let emp of empList"  [value]="emp.name"
                              (click)="empSelect(emp)"
                            >
                            {{emp.name}}
                        </mat-option>
                            <mat-option
                              [value]="null"
                              *ngIf="employeeNotFound"
                              (click)="noEmployeeFound()"
                              >No Employee Found</mat-option
                            >
                          </mat-autocomplete>
                        
                          <span *ngIf="empolyeeapproverexist" class="span-error">
                            employee Already Exist</span
                          > -->
                          
                    </mat-form-field> 
                    <div class="valid-msg" style="position: relative; top: -15px;" *ngIf="(validate.geofence.invalid && validate.geofence.touched) || validate.geofence.dirty || submitted">
                        <span *ngIf="validate.geofence.errors?.required" class="text-danger">Please select Employee </span>
                    </div>
                </div>
            </div>
           
            <div class="row pb-3" style="display: flex; justify-content: flex-end;">
                <div class="col-md-2 float-right">
                    <button mat-stroked-button  [routerLink]="['/client-assign']" color="warn" style="width: 100%;" type="button"
                  mat-dialog-close>Cancel</button>
               </div>
                <div class="col-md-2 pl-5  float-right">
                    <button  mat-stroked-button color="warn" style="width: 100%;" type="submit" mat-button [disabled]="isbtnDisable">
                    Create</button>
                </div>
            </div>
        </div>
        </form>
    </div>
</div>
  