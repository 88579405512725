<div class="card-new">
    <div class="row">
        <div class="col-md-12">
            <section class="my_prifile">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h3 class="profile-header-title">
                                            <mat-icon class="profile-icons">info</mat-icon>
                                            User Information
                                        </h3>
                                    </div>
                                    <div  class="col-md-4">
                                        <a><mat-icon class="float-right cancel-icon" (click)="close()">cancel</mat-icon></a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 text-center mb-5">
                                        <img *ngIf="profileImage" [src]="profileImage" alt="Profile Picture" class="profile-picture rounded-circle">
                                        <img *ngIf="!profileImage" src="assets/profile.png" alt="Profile Picture" class="profile-picture rounded-circle">
                                    </div>
                                    <div class="col-md-7" *ngIf="data?.deviceId">
                                        <div class="row d-flex align-items-center" style="margin-top: 30px;">
                                            <div class="col-md-4 form-control-label" style="margin-bottom: 0;"><label>Device ID</label></div>
                                            <div class="col-md-1">:</div>
                                            <div class="col-md-7"><span>{{data?.deviceId}}</span></div>
                                        </div>
                                        <div class="row d-flex align-items-center" style="margin-top: 20px;">
                                            <button mat-stroked-button color="primary" style="width: 45%;margin: 0 0 0 8px;" type="submit"
                                            [disabled]="!data?.deviceId" (click)="deviceIdReset()">
                                            Reset Device ID
                                          </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 form-control-label"> <label>Employee Role</label></div>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-6"><span>{{data.userType[0]?.userTypeName ? data.userType[0]?.userTypeName :""}}</span></div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-5  form-control-label"> <label>Employee No</label></div>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-6"><span>{{data.employee?.employeeNumber ? data.employee?.employeeNumber :""}}</span></div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-5  form-control-label"> <label>Employee Name</label></div>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-6"><span>{{data.name ? data.name :""}}</span></div>
                                </div>
                                <div class="row mt-2" *ngIf="data.email">
                                    <div class="col-md-5  form-control-label"><label>Email</label></div>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-6"><span>{{data.email}}</span></div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-5  form-control-label"><label>Mobile Number</label></div>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-6"><span>{{data.phoneNumber ? data.phoneNumber :""}}</span></div>
                                </div>
                                <div class="row mt-2 mb-3" *ngIf="data.employee?.designation">
                                    <div class="col-md-5  form-control-label"> <label>Designation</label></div>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-6"><span>{{data.employee?.designation.designationName}}</span></div>
                                </div>

                                <h3 class="profile-header-title" *ngIf="data.buddyUser">
                                    <mat-icon class="profile-icons">supervisor_account</mat-icon>
                                    Supervisor Details
                                </h3>
                                <div class="row mt-2" *ngIf="data.buddyUser">
                                    <div class="col-md-5  form-control-label"><label>Supervisor Name</label></div>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-6"><span>{{data.buddyUser?.name}}</span></div>
                                </div>
                                <div class="row mt-2" *ngIf="data.buddyUser">
                                    <div class="col-md-5  form-control-label"><label>Supervisor Email</label></div>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-6"><span>{{data.buddyUser?.email}}</span></div>
                                </div>
                                <div class="row mt-2 mb-3" *ngIf="data.buddyUser">
                                    <div class="col-md-5  form-control-label"><label>Supervisor Mobile Number</label></div>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-6"><span>{{data.buddyUser?.phoneNumber}}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
