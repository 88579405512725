
<div class="col-md-12" style="white-space: nowrap;">
  <h2 class="pl-5 pt-3 float-left">Assign Shift Employee</h2>
  <div class="float-right pt-3">
      <button mat-flat-button   [routerLink]="['/create-shift']">
          <span class="material-icons">
          add
          </span>
          <span>Assign Shift Employee</span>
      </button>
  </div>
</div>

<div class="row pl-5" style="padding: 15px;">
    <div class="col-lg-2 col-md-2 col-sm-12">
        <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
          <mat-label>Start Date</mat-label> 
          <input matInput  [(ngModel)]="startdate1"
            placeholder="Choose a Date"   (dateChange)="startdate($event)" 
             [matDatepicker]="picker1">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <span class="error_msg text-danger" *ngIf="staDate_required">*Please select startdate</span>
        <span class="error_msg text-danger " *ngIf="startDate_required">*Please select vaild startdate</span> 
    </div>
      <!-- </div> -->
      <div class="col-lg-2 col-md-2 col-sm-12">
        <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
            <mat-label>End Date</mat-label> 
            <input matInput  [(ngModel)]="enddate1"
              placeholder ="Choose a Date" (dateChange)="enddate($event)"  [min]="startDate"
             [matDatepicker]="picker2">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <span class="error_msg text-danger" *ngIf="endDate_required">*Please select enddate</span> 
        <span class="error_msg text-danger" *ngIf="endDate_error">Please select vaild enddate</span> 
        <!-- <span class="error_msg" >Please select end date</span> 
        <span class="error_msg" >End date should be greater than start date</span>   -->
      </div>
      <div class="col-lg-2 col-md-2 col-sm-12">
        <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
            <mat-label>Choose Shift</mat-label> 
            <mat-select placeholder="Select Shift" [(ngModel)]="shiftid"  (selectionChange)="isselecteShifts($event)">
            <mat-option [value]=undefined> -- Select Shift -- </mat-option>
            <mat-option *ngFor="let shift of shifts" [value]="shift.shiftId">{{ shift.shiftName }}
            </mat-option>
            </mat-select>
        </mat-form-field>
    <!-- <span class="error_msg" *ngIf="user_required">*Please select Geofence Name</span>  -->
</div>
    <div class="col-lg-2 col-md-2 col-sm-12">
            <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                <mat-label>Choose Client</mat-label> 
                <mat-select placeholder="Select Client" [(ngModel)]="clicentName"  (selectionChange)="isselectedname($event)" (selectionChange)=" onClientSelection($event)">
                <mat-option [value]=undefined> -- Select Client -- </mat-option>
                <mat-option *ngFor="let geofence of geoList"  [value]="geofence.clientId">{{geofence.clientCode}}-{{geofence.clientName}}</mat-option>
                </mat-select>
            </mat-form-field>
        <!-- <span class="error_msg" *ngIf="user_required">*Please select Geofence Name</span>  -->
    </div>
    <!-- <div class="col-lg-2 col-md-2 col-sm-12"> -->
      <!-- <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
          <mat-label>Choose Employee</mat-label> 
          <mat-select placeholder="Select Employee" [(ngModel)]="employee" [disabled]="!isClientSelected" (selectionChange)="isselectedemployesname($event)">
          <mat-option value="0"> -- Select Employee -- </mat-option>
          <mat-option *ngFor="let emp of empList"  [value]="emp.employeeId">{{emp.employeeNumber}} - {{emp.firstName}} {{emp.lastName}}</mat-option>
          </mat-select>
      </mat-form-field> -->
      <!-- <input 
      [(ngModel)]="employee"
            class="form-control email-input select-placeholder"
      placeholder="Select employee"
      [disabled]="!isClientSelected"
      [matAutocomplete]="auto"
      (click)="clearEmpArray()"
      (keyup)="empSearch($event)"
      style="height: 50px;
      margin-top: 5px;
      font-size: 15px;"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option value="All" (click)="empAllSelect()"  >All</mat-option>
     
      <mat-option
        *ngFor="let emp of empList"
        [value]="emp.employeeId"
        (click)="empSelect(emp)"
      >
      {{emp?.employeeNumber}} - {{emp?.firstName}} {{emp?.lastName}}
    </mat-option>
      <mat-option
        [value]="null"
        *ngIf="employeeNotFound"
        (click)="noEmployeeFound()"
        >No Employee Found</mat-option
      >
    </mat-autocomplete>
  
    <span *ngIf="empolyeeapproverexist" class="span-error">
      employee Already Exist</span
    >
     -->
      <!-- Approver Already Exists Message -->
     
<!-- </div> -->
<div class="col-lg-2 col-md-2 col-sm-12">
  <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
      <mat-label>Choose Location</mat-label> 
      <mat-select placeholder="Select Location" [(ngModel)]="locationName"   (selectionChange)="isSelectLocation($event)" [disabled]="!isClientSelected">
      <mat-option [value]=undefined> -- Select Location -- </mat-option>
      <!-- <mat-option value="Chennai">Chennai</mat-option> -->
      <mat-option *ngFor="let location of locationList"  [value]="location.locationId">{{location.address}}</mat-option>
      </mat-select>
  </mat-form-field>
</div>
<div class="col-lg-1 col-md-1 col-sm-12">
    <button type="button" class="btn btn-primary btm-sm" (click)="search()"  style="position: relative;bottom:-12px;font-size: 14px;">Search</button>
</div>

<table mat-table [dataSource]="dataSource" *ngIf="selectedEmployeeData?.length != 0  && tableShow" >
    
    <!-- Position Column -->
    <ng-container matColumnDef="sino">
      <th mat-header-cell *matHeaderCellDef> <b>Sl.NO</b> </th>
      <td mat-cell *matCellDef="let element; let i =index">  {{ currentPageOffset + i + 1 }} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="startDate" >
      <th mat-header-cell *matHeaderCellDef> <b>START DATE</b> </th>
      <td mat-cell *matCellDef="let element">  <!-- Reference element for each row -->
        {{element.startDate}}
      </td>
    </ng-container>
    <ng-container matColumnDef="endDate" >
      <th mat-header-cell *matHeaderCellDef> <b>END DATE</b> </th>
      <td mat-cell *matCellDef="let element">  <!-- Reference element for each row -->
        {{element.endDate}}
      </td>
    </ng-container>
    <ng-container matColumnDef="shift" >
      <th mat-header-cell *matHeaderCellDef> <b>SHIFT NAME</b> </th>
      <td mat-cell *matCellDef="let element">  <!-- Reference element for each row -->
        {{element.shift?.shiftName}}
      </td>
    </ng-container>
   
    <ng-container matColumnDef="employeeName" >
      <th mat-header-cell *matHeaderCellDef> <b>EMPLOYEE NUMBER/EMPLOYEE NAME</b> </th>
      <td mat-cell *matCellDef="let element">  <!-- Reference element for each row -->
        <!-- {{element.employee.employeeNumber}} - {{element.user.name}} -->
        {{element?.employee?.employeeNumber}} - {{element.employee?.user?.name}}
      </td>
       <!-- <td mat-cell *matCellDef="let element">1108-USHA</td> -->

    </ng-container>
    <ng-container matColumnDef="clientName" >
      <th mat-header-cell *matHeaderCellDef> <b>CLIENT CODE/CLIENT NAME</b> </th>
      <td mat-cell *matCellDef="let element">  <!-- Reference element for each row -->
        {{element.client?.clientCode}}-{{element.client?.clientName}}
      </td>
    </ng-container>
    <!-- <ng-container matColumnDef="locationAdd" >
      <th mat-header-cell *matHeaderCellDef> <b>LOCATION / ADDRESS</b> </th>
      <td mat-cell *matCellDef="let element">  
        {{ element.locationDetails?.locationName ? element.locationDetails.locationName : '-' }} <br>
        {{ element.locationDetails?.address ? element.locationDetails.address : '-' }}
      </td>
    </ng-container> -->
    
   <!-- Column in table -->


<ng-container matColumnDef="locationAdd">
  <th mat-header-cell *matHeaderCellDef class="col-md-3 col-sm-3"><b>LOCATION/ADDRESS</b></th>
  <td mat-cell *matCellDef="let element"> 
    <button *ngIf="element?.locationDetails; else nodata" type="button" class="btn b1" style="font-size: 12px;"
    (click)="viewclientaddress(element)">
      View Location
    </button>
    <ng-template #nodata>--</ng-template>
  </td>              
</ng-container>

    <!-- <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef> <b>ACTION</b>  </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="text-center" (click)="deleteemployee(i)">
          <mat-icon style="    vertical-align: middle;
          color: red;
          position: relative;
          right: 94px;">delete</mat-icon>
        </div>
      </td>
    </ng-container> -->


    <!-- <ng-container matColumnDef="shift">
      <th mat-header-cell *matHeaderCellDef><b>SHIFT</b></th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill" style="margin-top: 5px;">
          <mat-select [(ngModel)]="element.selectedShift" style="padding: 6px;">
            <mat-option *ngFor="let shift of shifts" [value]="shift.shiftId">
              {{ shift.shiftName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container> -->
    
 

  

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <!-- <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4" align="center">
        <div class="alert alert-secondary" style="width: 100%;">
           <h3>No Shift Record Found</h3>
        </div>
      <td>
    </tr> -->
   

  </table>
  

  <div class="mat-row" *ngIf="nodata">
    <div class="mat-cell" colspan="6" id="nodata" style="text-align: center;
    background: #d44c39fa;
    padding: 15px;">No shift data found
  
    </div>

  <!-- <div class="col-lg-1 col-md-1 col-sm-12 float-end"></div>
    <button type="button" class="btn btn-primary btm-sm" (click)="submit()"  [disabled]="btndisable"
    style="      position: relative;
    bottom: -12px;
    font-size: 14px;
    width: 62px;
    left: 75%;">Submit</button> -->
</div>

<mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5,10,20,30,50,100]"   showFirstLastButtons ></mat-paginator> 


<!-- </div>  -->