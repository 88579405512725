<div>
  <ul>
    <li>{{storeLocationDetails}}</li>
  </ul>  
</div>
  <!-- Mat Table code -->
  <!-- <div *ngIf="dataSource.filteredData.length>0;else noEmp">
  <div>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="sino">
          <th mat-header-cell *matHeaderCellDef class="text-center"> <b>Sl.No</b> </th>
          <td mat-cell *matCellDef="let element; let i =index" class="text-center"> {{ currentPageOffset + i + 1 }}</td>
        </ng-container>
  
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef> <b>Location/Address</b> </th>
          <td mat-cell *matCellDef="let element"> {{element?.locationDetails?.address }} </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> 
    </table>
    <div *ngIf="dataSource.filteredData.length>5">
      <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5,10,20,30,50,100]" showFirstLastButtons></mat-paginator>
    </div>
  </div> -->
  <!-- <ng-template #noEmp>
    <div class="p-3">
      <div class="no-data card text-center" style="text-align: center !important;
      background-color: #d44c39fa;
      border-radius: 5px;">
        <div class="card-body">No Location</div>
     </div>
    </div>
  </ng-template> -->
  
    
    
  
  