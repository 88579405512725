
<h1 mat-dialog-title>
    {{ data.type === 'address' ? ' Addresses' : ' Client Names' }}
  </h1>
  
  <div mat-dialog-content>
    <ng-container *ngIf="data.type === 'address'">
      
      <ng-container *ngFor="let client of data.clients">
        <p>{{ client.addressLine1 }}</p>
      </ng-container>
    </ng-container>
  
    <ng-container *ngIf="data.type === 'clientName'">
      
      <ng-container *ngFor="let client of data.clients">
        <p>{{ client.clientCode }} - {{ client.clientName }}</p>
      </ng-container>
    </ng-container>
  </div>
  
  <!-- <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
  </div>
   -->
