import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import Swal from 'sweetalert2';
import { GlobalserviceService } from '../providers/globalservice.service';
import { AuthService } from '../Authenticaton/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-createclientassign',
  templateUrl: './createclientassign.component.html',
  styleUrls: ['./createclientassign.component.scss']
})
export class CreateclientassignComponent implements OnInit {
  assignForm:FormGroup;
  userlist: any=[];
  // empList: any=[];
  submitted: boolean = false;
  isDisabled = true;
  allSelected:boolean=false;
  totalData:any=[];
  selectedUsersControl = new FormControl();
  @ViewChild('select') select: MatSelect;
  type: any;
  user: any;
  geofence: any;
  userId: any;
  user_required: boolean;
  activeEmployeesData: any;
clientList: any=[];
  storeClientId: any;
  storeOrgId: any;
employeeNotFound: any;
empolyeeapproverexist: any;
isClientSelected: boolean=false;
employee: any;
private unsubscribe$: Subject<void> = new Subject<void>();

client: any;
empList: any[]=[];
  employeelistsub: any;
  postdatamultiple: never[];
  selectedEmployeeData: any;
  employeeId: any;
  disableFeildforAll: boolean=false;
  employees: string;
  users: any;
  userIds: any;
  isbtnDisable: boolean=false;
  storeEmpId: any;
  storeEmp: any;
  storinguserId: any;
  employeesArray: any=[];
setBoolean: boolean=false;
  constructor(private gs: GlobalserviceService, 
    private fb:FormBuilder, 
    private auth: AuthService,
     private router: Router, 
     public dialog: MatDialog,
     private datepipe:DatePipe) { }

  ngOnInit(): void {
    let  userdata = this.auth.getLocalStorageData();
      console.log(userdata);
      this.userIds=userdata.userId;
      this.storeOrgId=userdata.organization.organizationId
      console.log("this.storeOrgId",this.storeOrgId);
      
      console.log(this.userIds);
      let id = this.auth.getLocal_Org().organizationId
      console.log(userdata .userId)
      // this.isselecteduser(event)

    this.assignForm = this.fb.group({
      client:['',[Validators.required]],
      geofence:['',[Validators.required]]
    })
    // this.gs.get_User_list().subscribe((data: any) => {
    //   console.log(data);
    //   this.clientList=data;
    //   console.log(this.clientList);
    // })
    this.gs.get_client_org(this.storeOrgId).subscribe((a: any) => {
      console.log(a);
      this.clientList=a;
  //    //  this.activeEmployeesData=this.clientList
      this.clientList = Array.isArray(a) ? a : [a];
  //    //  this.storeClientId=this.clientList.clientId;
  //    //  console.log(" this.storeClientId::", this.storeClientId);
  //    this.clientList.forEach((client: any) => {
  //      this.storeClientId = client.clientId; // or push to an array if you have multiple clientIds
  //      console.log("this.storeClientId::", this.storeClientId);
  //  });
  this.setBoolean=true

  if(a.statusCode==209){
    this.clientList=[]
    this.setBoolean=false;
    console.log("entered here!!");
    
  }
  },
  (error:any)=>{
    console.log("error",error);
    if(error.errorCode==204){
      this.clientList=[]
    }
  }
)
 
    this.gs.get_user_AllemployeeList(this.storeOrgId).subscribe((d: any) => {
      console.log('Employee response:', d);
      // this.empList = d;
    //  this.empList = Array.isArray(d) ? d : [d];
     d.map(
      (e: any)=>{
          if(userdata.userType[0].userTypeName == "BUDDY USER")
          {
            console.log("if");
           if(e.userId !=  userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
          && e.buddyUser?.userId == userdata.userId){
             console.log(e)
              this.empList.push(e);
              console.log("buddy user listing",this.empList);
           }
          }else {
           if(e.userId !=  userdata.userId  ){
             console.log(e)
              this.empList.push(e);
              console.log("primary user listing",this.empList);
           }
          }
        }
   )

    //   this.employeesArray = this.empList.map((user: any) => {
    //     return { employeeId: user.UserId };
    // });
  })
  
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select?.options.forEach((item: MatOption) => item.select());
      this.geofence?.setValue(this.empList.map(e => e.employeeId));
    } else {
      this.select?.options.forEach((item: MatOption) => item.deselect());
      this.geofence?.setValue([]);
    }
  }
  individualCheckboxChanged() {
    // const geofenceControl = this.assignForm.get('geofence');
  
    // if (geofenceControl && geofenceControl.value) { // Adding null checks
    //   const selectedEmployeeIds = geofenceControl.value;
  
    //   if (selectedEmployeeIds.length === this.activeEmployeesData?.length && selectedEmployeeIds?.length !== 0) {
    //     this.allSelected = true;
    //     console.log("if entering inside option");
    //   } else {
    //     this.allSelected = false;
    //     console.log("else condition");
    //   }
    // } else {
    //   console.log("Form control is null or undefined");
    // }
    const selectedEmployeeIds = this.geofence?.value;
    if (selectedEmployeeIds.length === this.activeEmployeesData.length && selectedEmployeeIds.length !== 0) {
      this.allSelected = true;
      console.log("if entering inside option");
    } else {
      this.allSelected = false;
      console.log("else condition");
    }
  }
  
  isselecteduser(event) {
    this.storeClientId=event.value;
   
  }
  
  

  clearEmpArray(){
    this.empList=[]
  }

  assigntype(event:any)
  {
    console.log(event);
    this.type=event.value;
    console.log(this.type);
  }
  get validate() { return this.assignForm?.controls; }
  selectuser(event:any)
  {
  console.log("user",event.value);
  this.users=event.value;
  console.log(this.users);
  }
  geofenceselect(event:any)
  {
    console.log("geofence",event.value);
    this.geofence=event.value;
    console.log(this.geofence);
  } 
  userselect(event:any)
  {
    console.log("user",event.value);
    this.users=event.value;
    console.log(this.users);
  }
  selectgeofence(event:any)
  {
    console.log("geofence",event.value);
    this.geofence=event.value;
    console.log(this.geofence);
  }
  empSelect(emp) { 
    // Reset some flags and fields
    this.disableFeildforAll = false;
    this.isClientSelected = false;

    // Log the selected employee
    console.log(emp);

    

    // Store selected employee details
    this.employeeId = emp.userId;
    this.employees = `${emp.name}`;
    console.log(this.employees);
    this.employees=this.employee

    if (emp) {
        console.log("if emp came here!!!");

        // Check if employee already exists in the selectedEmployeeData array
        const exists = this.selectedEmployeeData.find((e) => e.employeeId === emp.employeeId);

        if (!exists) {
            console.log("if not existed, came here!!!");

            // Add the selected employee to the array only if it does not exist
            this.selectedEmployeeData.push(emp);
        } else {
            console.log("else already exists, came here!!!");
            this.empolyeeapproverexist = true; // Show error message for existing employee
        }

        // Update the MatTableDataSource
    }

    // Check if a client is selected
    if (this.client) {
        console.log("Client selected");
        this.isClientSelected = true; // Enable fields if a client is selected

        this.gs.get_employee_byClient_list( this.storeOrgId).subscribe((d: any) => {
            console.log('Employee response:', d);
            this.empList = d;

           
            

            // Ensure the selected employee is added only once
            if (!this.selectedEmployeeData.find((e) => e.userId === emp.UserId)) {
                this.selectedEmployeeData.push(emp);
            }

            // Update the data source
        });
    } else {
        console.log("No client selected");
        this.employee = null; // Clear employee field if no client is selected

        // Check if the employee is already selected
        const hasEmployee = this.selectedEmployeeData.some(e => e.userId === emp.userId);
        if (!hasEmployee) {
            this.selectedEmployeeData.push(emp); // Add employee to the selected list
            console.log("Updated selectedEmployeeData", this.selectedEmployeeData);
        } else {
            console.log("Employee already exists in the list");
        }

        // Ensure the data source for the table is updated
    }

    // Uncomment if you want to manage validators
    // if (this.selectedEmployeeData.length >= 1) {
    //     this.employee?.clearValidators();
    // } else {
    //     this.employee?.setValidators([Validators.required]);
    // }
    // this.employee?.updateValueAndValidity();
}

  empSearch(event){ 
    // this.empolyeereadonly=false;
    this.postdatamultiple = [];
    this.employeeNotFound = false; // Reset the no employee flag
    console.log(event.target.value);
    let employeenumber = event.target.value;
    console.log(employeenumber);
    
    // Employee List API
    if(employeenumber.length >= 2){
      if(this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }
      this.employeelistsub = this.gs.getUserNameList(event.target.value)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          switchMap(() => this.gs.getUserNameList(event.target.value))
        )
        .subscribe((result: any) => {
          this.empList = [];
          
          if (result.statusCode) {
            console.log(result.statusCode);
            this.empList = [];
            this.employeeNotFound = true; // Set the flag if status code indicates no employees
          } else {
            this.empList = result;
            if (this.empList.length === 0) {
              this.employeeNotFound = true; // Set the flag if the list is empty
            }
          }
        
  
        })
      }
      else{
        //call selected client employees API here
      }
    }
  isselectedname(event:any)
  {
    const selectedEmployees = event.value;
  this.storeEmpId = [];

  if (this.allSelected) {
    // When 'Select All' is chosen, push each employee ID as a separate object
    selectedEmployees.forEach((empId: number) => {
      this.storeEmpId.push({ employeeId: empId });
    });
  } else {
    // For both single and multiple specific employee(s), push each employee ID as a separate object
    selectedEmployees.forEach((empId: number) => {
      this.storeEmpId.push({ employeeId: empId });
    });

    // this.gs.get_user_AllemployeeList(this.storeOrgId).subscribe((d: any) => {
    //   console.log(d);
    //   this.empList=d;
    //   this.empList = Array.isArray(d) ? d: [d];
    //   console.log(this.empList);
    // })
    // console.log("selectGeofenceName");
  }}
  onSubmit()
  {
    console.log("Submit");
    this.submitted=false;
    // if (this.assignForm.invalid) 
    // {
    //   console.log("invalid")
    //   return;
    // }
    // if(this.client==undefined && this.employee==undefined){
    //   this.isbtnDisable=true
    // }
    this.isbtnDisable=false

    if(this.assignForm.invalid){
      this.submitted=false;
      return
    }

   
  //   const creationData = this.assignForm.value;
  //   console.log("creationData::",creationData);
    

  //   const payload = {
  //     clientId: creationData. clientId,
  //     employees: {
  //       employeeId:creationData.employeeId
  //     }

      
  // }

  // Check empList structure
    // console.log(this.empList); // Debugging line

    // const employees = this.empList
    //  .map(emp => ({
    //     employeeId: emp.employee?.employeeId // Adjust this if your structure is different
    // }))
    // .filter(emp => emp.employeeId !== undefined); // Filter to avoid undefined values

  //   const payload = {
  //   clientId: this.storeClientId,
  //   employees: employees
  // };

  //   console.log(payload); // Verify the final output



  //   console.log("payload::",payload);
  // const multiplePayload = this.empList.map((employee: any) => ({
  //      clientId: this.storeClientId,
  //      employees: {
  //      employeeId: employee.employeeId 
  //   },
    
  // }));

  const payload = {
    clientId: this.storeClientId,
    employees: this.storeEmpId
  };


    console.log("payload mulitple",payload);

  

    
    // return

    this.gs.createClientAssign(this.storeClientId,payload).subscribe((d:any)=>{
      console.log(d);
      if(d.statusCode == 200){
        this.submitted=false;
        Swal.fire({
          text:d.message,
          icon:'success',
          timer: 4000,
        })
        setTimeout(() => {
           this.router.navigateByUrl('/client-assign');
        }, 4000)
      }
        if(d.statusCode == 404){
          Swal.fire({
            text:d.message,
            icon:'error',
            timer: 3000
          })
          setTimeout(() => {
          }, 2000)
        }
       
      if (Array.isArray(d)) {
        // Handle array response
        const errorMessages = d
            .filter((item: any) => item.statusCode === 409) // Filter responses with statusCode 409
            .map((item: any) => item.description); // Get the error description

        if (errorMessages.length > 0) {
            Swal.fire({
                text: errorMessages.join(', '), // Display all error messages
                icon: 'error',
                timer: 5000,
                width:800

            });
            setTimeout(() => {
              this.router.navigateByUrl('/client-assign');

            }, 5000)
            // this.btndisable = false; // Enable button if there is an error
            return;
        }
    }
    },
    err=>{
      console.log("Error");
      Swal.fire({
        text:err.error.description,
        icon:'error',
        timer: 2000
      })
      setTimeout(() => {
      }, 2000)
    }
     );
  }

  
  empAllSelect() {
    if (this.employee === "All") {
      // Disable the field immediately after selecting "All"
      this.disableFeildforAll = true;
  
      // Fetch the employee list
      this.gs.get_employee_byClient_list(this.storeOrgId).subscribe((response: any) => {
        console.log('Employee response:', response); // Log the response for debugging
  
        // Check if the response is an array and assign it properly
        if (Array.isArray(response)) {
          this.empList = response;
          this.selectedEmployeeData = this.empList;
  
          // Concatenate all employee names and set to the employee field
          this.employee = this.empList.map(emp => emp.name).join(', ');
  
          // Map employee IDs and prepare for the payload
          this.employeesArray = this.empList.map(emp => ({
            employeeId: emp.employeeId  // Assuming employeeId is the property in your response
          }));
  
        } else {
          console.error('Response is not an array', response);
        }
      }, (error) => {
        console.error('Error fetching employee list', error); // Handle error
      });
    }
  }
  
  
  noEmployeeFound() {
    this.employeeNotFound = false;
    this.employeeId = undefined;
    this.employee = undefined;
  }
}
