import { Component, OnInit ,Inject, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-viewemployee',
  templateUrl: './viewemployee.component.html',
  styleUrls: ['./viewemployee.component.scss']
})
export class ViewemployeeComponent implements OnInit {
  dataSource = new MatTableDataSource;
  currentPageOffset: number = 0; 
  filterData:any[]=[]
  @ViewChild(MatPaginator) paginator:any;

  displayedColumns: string[] = ['sino', 'empNumbers', 'employeeName'];
  constructor(public dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    console.log('data',this.data);
    // this.filterData.push(this.data)
    // console.log("filterdata::",this.filterData);
    
    this.dataSource=new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;

    console.log("data source:",this.dataSource);
    

  }
  onPageChange(event: any) {
    console.log("index",event.pageIndex);
    console.log("size",event.pageSize);
    this.currentPageOffset = event.pageIndex * event.pageSize;
    }
}
