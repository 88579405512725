<div class="container">
  <div class="mat-elevation-z8">
    <h2 class="pl-5 pt-3">Overall User Login & logout Reports
    </h2>
    <div class="row pl-5" style="">
      <div class="col-lg-3 col-md-3 col-sm-12">
        <mat-form-field
          class="example-full-width"
          appearance="outline"
          style="display: inline"
        >
          <!-- <mat-label>select assign type</mat-label> -->
          <mat-select
            placeholder="Select Assign Type"
            (selectionChange)="assigntype($event)"
          >
            <mat-option value="1">Loggedin Employee Details</mat-option>
            <mat-option value="2">Loggedout Employee Details</mat-option>
            <mat-option value="3">Monthly Details</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="type != undefined">
      <div class="row pl-5" *ngIf="type == 1">
        <ng-container *ngIf="isLoading; else elseTemplate">
            <div class="loading">
              <app-loading></app-loading>
              <br>
              <h1>Please Wait Loading...</h1>
            </div>
          </ng-container>
          
          <ng-template #elseTemplate>
              <div class="container mb-5">
                <div class="row " style="">
                    <!-- <div class="col-lg-3 col-md-3 col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                            <mat-label>Choose User </mat-label> 
                            <mat-select placeholder="Select User" formControlName="user"  >
                                <mat-option value="0"> -- Select User -- </mat-option>
                                <mat-option *ngFor="let user of userlist"  [value]="user.userId">{{user.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                   </div> -->
        
                    <div class="col-lg-3 col-md-3 col-sm-12 mb-3">
                            <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                                <mat-label>Select Client</mat-label> 
                                <mat-select placeholder="Select Client" formControlName="geofenceName"  >
                                <mat-option value="0"> -- Select Geofence Name -- </mat-option>
                                <mat-option  >geofence.geofenaceName</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                    </div>
                <div class="col-lg-1 col-md-1 col-sm-12">
                    <button type="button" class="btn btn-primary btm-sm"   style="position: relative;bottom:-12px;font-size: 14px;">Search</button>
                </div>
            </div> 
                <div class="mat-elevation-z8">
              
            
            
                  <table mat-table [dataSource]="dataSource">
              
                    <!-- Position Column -->
                    <ng-container matColumnDef="locationId">
                      <th mat-header-cell *matHeaderCellDef> <b>Sl.No</b> </th>
                      <td mat-cell *matCellDef="let element; let i =index"> {{ currentPageOffset + i + 1 }}</td>
                    </ng-container>
              
                    <!-- Name Column -->
                    <ng-container matColumnDef="latitude">
                      <th mat-header-cell *matHeaderCellDef> <b>LATITUDE</b> </th>
                      <td mat-cell *matCellDef="let element"> {{element.latitude}} </td>
                    </ng-container>
              
              
              
                    <!-- Weight Column -->
                    <ng-container matColumnDef="longitude">
                      <th mat-header-cell *matHeaderCellDef> <b>LONGITUDE</b> </th>
                      <td mat-cell *matCellDef="let element"> {{element.longitude}} </td>
                    </ng-container>
              
                    <!-- Symbol Column -->
                    <!-- <ng-container matColumnDef="address">
                      <th mat-header-cell *matHeaderCellDef> <b>ADDRESS</b> </th>
                      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                    </ng-container> -->
                   
              
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="Action">
                      <th mat-header-cell *matHeaderCellDef> <b>ACTION</b> </th>
                      <td mat-cell *matCellDef="let element">
                        <button mat-icon-button aria-label="Example icon-button with a menu"   [matMenuTriggerFor]="beforeMenu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <div class="side_setting">
          
                          <mat-menu #beforeMenu="matMenu" xPosition="before">
                            <a  mat-menu-item  (click)="editLocation(element)" > <span class="material-icons v"
                              style="    transform: translateY(7px);font-size: 22px;margin-right:3px">edit</span> Edit 
                              Location</a>
                            <a  mat-menu-item  (click)="viewaddress(element)" >
                              <span class="material-icons v" style="    transform: translateY(7px);font-size: 22px;;margin-right:3px" >visibility</span>View Address</a>               
                          </mat-menu>
                        </div>
          
                     
                        
                        
                       
                      </td>
                    </ng-container>
              
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="4" align="center">
                        <div class="alert alert-secondary" style="width: 128%;">
                          <h3>No Location List Found </h3>
                        </div>
                      <td>
                    </tr>
                  </table>
              
              
                  <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5, 10, 20,30,50]" showFirstLastButtons></mat-paginator>
              
                </div>
              </div>
              </ng-template>
            
            
      </div>
    </div>

    <div *ngIf="type != undefined">
        <div class="row pl-5 " *ngIf="type == 2">
            <ng-container *ngIf="isLoading; else elseTemplate">
                <div class="loading">
                  <app-loading></app-loading>
                  <br>
                  <h1>Please Wait Loading...</h1>
                </div>
              </ng-container>
              
              <ng-template #elseTemplate>
                  <div class="container mb-5">
                    <div class="row " style="">
                        <!-- <div class="col-lg-3 col-md-3 col-sm-12">
                            <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                                <mat-label>Choose User </mat-label> 
                                <mat-select placeholder="Select User" formControlName="user"  >
                                    <mat-option value="0"> -- Select User -- </mat-option>
                                    <mat-option *ngFor="let user of userlist"  [value]="user.userId">{{user.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            
                       </div> -->
                       <div class="col-lg-3 col-md-3 col-sm-12 mb-3">
                        <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                            <mat-label>Select Client</mat-label> 
                            <mat-select placeholder="Select Client" formControlName="geofenceName"  >
                            <mat-option value="0"> -- Select Geofence Name -- </mat-option>
                            <mat-option  >geofence.geofenaceName</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                </div>
            
                        <div class="col-lg-3 col-md-3 col-sm-12 mb-3">
                                <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                                    <mat-label>Select Client</mat-label> 
                                    <mat-select placeholder="Select Client" formControlName="geofenceName"  >
                                    <mat-option value="0"> -- Select Geofence Name -- </mat-option>
                                    <mat-option  >geofence.geofenaceName</mat-option>
                                    </mat-select>
                                </mat-form-field>
            
                        </div>
                    <div class="col-lg-1 col-md-1 col-sm-12">
                        <button type="button" class="btn btn-primary btm-sm"   style="position: relative;bottom:-12px;font-size: 14px;">Search</button>
                    </div>
                </div> 
                    <div class="mat-elevation-z8">
                  
                
                
                      <table mat-table [dataSource]="dataSource">
                  
                        <!-- Position Column -->
                        <ng-container matColumnDef="locationId">
                          <th mat-header-cell *matHeaderCellDef> <b>Sl.No</b> </th>
                          <td mat-cell *matCellDef="let element; let i =index"> {{ currentPageOffset + i + 1 }}</td>
                        </ng-container>
                  
                        <!-- Name Column -->
                        <ng-container matColumnDef="latitude">
                          <th mat-header-cell *matHeaderCellDef> <b>LATITUDE</b> </th>
                          <td mat-cell *matCellDef="let element"> {{element.latitude}} </td>
                        </ng-container>
                  
                  
                  
                        <!-- Weight Column -->
                        <ng-container matColumnDef="longitude">
                          <th mat-header-cell *matHeaderCellDef> <b>LONGITUDE</b> </th>
                          <td mat-cell *matCellDef="let element"> {{element.longitude}} </td>
                        </ng-container>
                  
                        <!-- Symbol Column -->
                        <!-- <ng-container matColumnDef="address">
                          <th mat-header-cell *matHeaderCellDef> <b>ADDRESS</b> </th>
                          <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                        </ng-container> -->
                       
                  
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="Action">
                          <th mat-header-cell *matHeaderCellDef> <b>ACTION</b> </th>
                          <td mat-cell *matCellDef="let element">
                            <button mat-icon-button aria-label="Example icon-button with a menu"   [matMenuTriggerFor]="beforeMenu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            <div class="side_setting">
              
                              <mat-menu #beforeMenu="matMenu" xPosition="before">
                                <a  mat-menu-item  (click)="editLocation(element)" > <span class="material-icons v"
                                  style="    transform: translateY(7px);font-size: 22px;margin-right:3px">edit</span> Edit 
                                  Location</a>
                                <a  mat-menu-item  (click)="viewaddress(element)" >
                                  <span class="material-icons v" style="    transform: translateY(7px);font-size: 22px;;margin-right:3px" >visibility</span>View Address</a>               
                              </mat-menu>
                            </div>
              
                         
                            
                            
                           
                          </td>
                        </ng-container>
                  
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="4" align="center">
                            <div class="alert alert-secondary" style="width: 128%;">
                              <h3>No Location List Found </h3>
                            </div>
                          <td>
                        </tr>
                      </table>
                  
                  
                      <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5, 10, 20,30,50]" showFirstLastButtons></mat-paginator>
                  
                    </div>
                  </div>
                  </ng-template>
                
                
        </div>
      </div>

      <div *ngIf="type != undefined">
        <div class="row pl-5" *ngIf="type == 3">
            <ng-container *ngIf="isLoading; else elseTemplate">
                <div class="loading">
                  <app-loading></app-loading>
                  <br>
                  <h1>Please Wait Loading...</h1>
                </div>
              </ng-container>
              
              <ng-template #elseTemplate>
                  <div class="container mb-5">
                    <div class="row " style="">
                      
            
                       <div class="col-lg-3 col-md-3 col-sm-12 mb-3">
                        <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                            <mat-label>Select Client</mat-label> 
                            <mat-select placeholder="Select Client" formControlName="geofenceName"  >
                            <mat-option value="0"> -- Select Geofence Name -- </mat-option>
                            <mat-option  >geofence.geofenaceName</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                </div> 
                        <div class="col-lg-3 col-md-3 col-sm-12 mb-3">
                                <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                                    <mat-label>Select Client</mat-label> 
                                    <mat-select placeholder="Select Client" formControlName="geofenceName"  >
                                    <mat-option value="0"> -- Select Geofence Name -- </mat-option>
                                    <mat-option  >geofence.geofenaceName</mat-option>
                                    </mat-select>
                                </mat-form-field>
            
                        </div>
                    <div class="col-lg-1 col-md-1 col-sm-12">
                        <button type="button" class="btn btn-primary btm-sm"   style="position: relative;bottom:-12px;font-size: 14px;">Search</button>
                    </div>
                </div> 
                    <div class="mat-elevation-z8">
                  
                
                
                      <table mat-table [dataSource]="dataSource">
                  
                        <!-- Position Column -->
                        <ng-container matColumnDef="locationId">
                          <th mat-header-cell *matHeaderCellDef> <b>Sl.No</b> </th>
                          <td mat-cell *matCellDef="let element; let i =index"> {{ currentPageOffset + i + 1 }}</td>
                        </ng-container>
                  
                        <!-- Name Column -->
                        <ng-container matColumnDef="latitude">
                          <th mat-header-cell *matHeaderCellDef> <b>LATITUDE</b> </th>
                          <td mat-cell *matCellDef="let element"> {{element.latitude}} </td>
                        </ng-container>
                  
                  
                  
                        <!-- Weight Column -->
                        <ng-container matColumnDef="longitude">
                          <th mat-header-cell *matHeaderCellDef> <b>LONGITUDE</b> </th>
                          <td mat-cell *matCellDef="let element"> {{element.longitude}} </td>
                        </ng-container>
                  
                        <!-- Symbol Column -->
                        <!-- <ng-container matColumnDef="address">
                          <th mat-header-cell *matHeaderCellDef> <b>ADDRESS</b> </th>
                          <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                        </ng-container> -->
                       
                  
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="Action">
                          <th mat-header-cell *matHeaderCellDef> <b>ACTION</b> </th>
                          <td mat-cell *matCellDef="let element">
                            <button mat-icon-button aria-label="Example icon-button with a menu"   [matMenuTriggerFor]="beforeMenu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            <div class="side_setting">
              
                              <mat-menu #beforeMenu="matMenu" xPosition="before">
                                <a  mat-menu-item  (click)="editLocation(element)" > <span class="material-icons v"
                                  style="    transform: translateY(7px);font-size: 22px;margin-right:3px">edit</span> Edit 
                                  Location</a>
                                <a  mat-menu-item  (click)="viewaddress(element)" >
                                  <span class="material-icons v" style="    transform: translateY(7px);font-size: 22px;;margin-right:3px" >visibility</span>View Address</a>               
                              </mat-menu>
                            </div>
              
                         
                            
                            
                           
                          </td>
                        </ng-container>
                  
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="4" align="center">
                            <div class="alert alert-secondary" style="width: 128%;">
                              <h3>No Location List Found </h3>
                            </div>
                          <td>
                        </tr>
                      </table>
                  
                  
                      <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5, 10, 20,30,50]" showFirstLastButtons></mat-paginator>
                  
                    </div>
                  </div>
                  </ng-template>
                
                
        </div>
      </div>
  </div>
</div>
