<ng-container *ngIf="loading">
  <div class="loader">
    <app-loading></app-loading>
  </div>
</ng-container>
<mat-card class="col-md-8">
  <br />
  <mat-card-title>{{clientId ? 'Update' : 'Create'}} Client </mat-card-title>
  <br />
  <!-- <div class="col-lg-4 col-md-2 col-sm-12"> -->

  <!-- </div> -->
  <form action="" [formGroup]="AddLocationForm" (ngSubmit)="addLocation()">
    <div>
      <div class="row">
        <div class="form-group col-lg-4">
          <label> Client Code</label>
          <input
            type="text"
            placeholder="Enter Client Code"
            formControlName="clientcode"
            class="form-control"
            style="font-size: 14px;"
          />
          <mat-error *ngIf="f.clientcode.hasError('required') && (f.clientcode.touched || f.clientcode.dirty  || submitted)">
            Please select <strong>Client Code</strong>
          </mat-error>
          
          <mat-error *ngIf="f.clientcode.hasError('pattern') && (f.clientcode.touched || f.clientcode.dirty || submitted)">
            Please enter a valid Client Code
          </mat-error>
        </div>
        <div class="form-group col-lg-4">
          <label> Client Name</label>
          <input
            type="text"
            placeholder="Enter Client Name"
            formControlName="clientname"
            class="form-control"
            style="font-size: 14px;"
          />
          <!-- <mat-error *ngIf="f.clientname.hasError('required')">
            Please select <strong>Client Name</strong>
          </mat-error>
          <mat-error *ngIf="f.clientname.hasError('pattern')">
            Please enter a valid Client Name
          </mat-error> -->
          <mat-error *ngIf="f.clientname.hasError('required') && (f.clientname.touched || f.clientname.dirty || submitted )">
            Please select <strong>Client Name</strong>
          </mat-error>
          
          <mat-error *ngIf="f.clientname.hasError('pattern') && (f.clientname.touched || f.clientname.dirty || submitted )">
            Please enter a valid Client Name
          </mat-error>
        </div>

        <!-- <div class="col-lg-1 col-md-1 col-sm-12 mt-2">
            <button type="button" class="btn btn-primary btm-sm"   style="position: relative;bottom:-12px;font-size: 14px;height: 25px;">Create Client</button>
        </div> -->
        <div class="col-lg-1 mt-4" style="margin-top: inherit;">
          <button type="submit" class="btn btn-primary btm-sm mt-3"style="font-size: 14px;">{{clientId ? 'Update' : 'Create'}} Client</button>
        </div>
      </div>

      <!-- <div class="form-group">
        <label> Address</label>
        <input
          type="text"
          id="address"
          formControlName="AddressName"
          class="form-control"
          (keydown.enter)="$event.preventDefault()"
          (keyup)="getaddress()"
          placeholder="Enter  Location"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="off"
          type="text"
          #search
        />
        <mat-error *ngIf="f.AddressName.hasError('required')">
          Please select <strong>Address</strong>
        </mat-error>
        <mat-error *ngIf="f.AddressName.hasError('pattern')">
          Please enter a valid address
        </mat-error>
      </div> -->

      <!-- <br />
      <agm-map
        [latitude]="latitude"
        [longitude]="longitude"
        [zoom]="zoom"
        (mapClick)="mapClicked($event)"
      > -->
        <!-- <agm-marker
          *ngFor="let marker of markers;let i = index"
          [latitude]="latitude"
          [longitude]="longitude"
          (dragEnd)="markerDragEnd($event,i)"
          (mapReady)="onMapReady($event)"
          [markerDraggable]="true"
        ></agm-marker> -->
        <!-- <agm-marker
          *ngFor="let marker of markers; let i = index"
          [latitude]="marker.lat"
          [longitude]="marker.lng"
          (dragEnd)="markerDragEnd($event, i)"
          [markerDraggable]="marker.draggable"
        ></agm-marker>
      </agm-map> -->

      <!-- <h5>Address: {{address}}</h5> -->
      <!-- <div>Latitude: {{ latitude }}</div>
      <div>Longitude: {{ longitude }}</div> -->
    </div>

    <!-- <div style="color: red" *ngIf="f.address.invalid">
      Please select the location to track
    </div>
    <div>
      <button
        mat-stroked-button
        color="warn"
        style="width: 100%; margin: 20px 0 0 8px"
        type="submit"
        mat-button
      >
        Create Location
      </button>
    </div> -->
  </form>
</mat-card>
