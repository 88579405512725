import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { url } from '../providers/properties';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router) { }

  logintest(username: string, password: string, deviceType:number) {
    return this
      .http
      .post(`${url}login/email`, { email: username, password: password,deviceType:deviceType});
  }

  verify_otp(otp: any, phoneNumber: any) {
    return this
      .http
      .get(`${url}validate_otp/${otp}/${phoneNumber}`);
  }
  verifyEmail(otp: any, email: any) {
    return this
      .http
      .post(`${url}check-otp`, [{ email: email, otp: otp }]);
  }

  getLocalStorageData() {
    let x: any = localStorage.getItem("PrintLok$")
    let y = JSON.parse(x)
    return y
  }

  get_local_userType() {
    let x: any = localStorage.getItem("PrintLok$")
    let y = JSON.parse(x)
    return y.userType[0].userTypeId
  }

  getLocal_Org() {
    let x: any = localStorage.getItem("PrintLok$")
    let y = JSON.parse(x)
    let z = y.organization
    return z
  }


  Resend_Otp(phonenumber: any) {
    return this
      .http
      .get(`${url}generate_otp/${phonenumber}`,);
  }

  get_local_userTypeName() {
    let x: any = localStorage.getItem("PrintLok$")
    let y = JSON.parse(x)
    return y.userType[0].userTypeName
  }
}
