import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select/select';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  userForm!: FormGroup
  matcher = new MyErrorStateMatcher();
  isLoading: boolean = false
  userdata :any;
  isOrg :any;
  userTypeList: any[] = [];
  selectedUserTypeId: any;
  buddyuserList:any =[];
  organizationId:any;
  designationlist:any =[];
  empStatus: any;
  filename: any;
  fileSize: any;
  fileType: any;
  fileByte: any;
x: any;

  constructor(private fb: FormBuilder, public dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any , public auth : AuthService , public gs :GlobalserviceService) { }

  ngOnInit(): void {

    console.log(this.data.attachment);
    console.log(this.data.userId);


    //  this.gs.get_EmployeeOnly(this.data.employee.employeeId).subscribe(emp => {
    //     console.log(emp);
    //     this.empStatus=emp;
    //   })

     this.gs.get_userId(this.data.userId).subscribe(emp => {
        console.log(emp);
        this.empStatus=emp;
        console.log(this.empStatus)
      })
    this.gs.getdesignation().subscribe(a => {
      console.log(a);
      this.designationlist = a;
    })
     this.selectedUserTypeId = this.data.userType[0].userTypeId;

    if (this.auth.getLocal_Org()?.organizationId) {
      this.organizationId = this.auth.getLocal_Org().organizationId;
      this.isOrg = true
    } else {
      console.log("else")
      this.isOrg = false
    }
    this.gs.get_usertype().subscribe(a => {
      console.log(a);
      let userTypeId = this.auth.get_local_userType()

      if (userTypeId == 1) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4
        })
        console.log("primary_user1",primary_user);
        this.userTypeList = primary_user;
        console.log("this.userTypeList1",this.userTypeList);
      }

      if (userTypeId == 2) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4 && u.userTypeId !== 2
        })
        console.log("primary_user2",primary_user);
        this.userTypeList = primary_user;
        console.log("this.userTypeList2",this.userTypeList);
      }

      // if (a.length > 0) {
      //   const data = a.filter(u => {
      //     return u.userTypeId !== 1 && u.userTypeId !== 4
      //   })
      //   console.log(data)
      //   this.userTypeList = data
      // }


    })
    this.gs.getdesignation().subscribe(a => {
      console.log(a);
      this.designationlist = a;
    })

    this.userForm = this.fb.group({
      name: ['', Validators.required],
      EmpNo: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.minLength(10),Validators.maxLength(10)]],
      // mobileNumber: ['', [Validators.required, Validators.minLength(10),Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      designation:['',Validators.required],
      userType: ['', Validators.required],
      buddyuser:[''],
      attachment:['',Validators.required]
    })
    console.log("usertype" +  this.data.userType[0].userTypeId);

    this.userForm.patchValue({
      EmpNo:this.data.employee.employeeNumber,
      name:this.data.name,
      mobileNumber:this.data.phoneNumber,
      email: this.data.email,
      designation:this.data.employee.designation.designationId,
      userType : this.data.userType[0].userTypeId,
      attachment: this.data.attachment.fileName // Patch the old attachment name

    })
    console.log("Path value",this.userForm);
    if( this.selectedUserTypeId == 3){
      this.gs.getbuddyuserByorg(2,this.organizationId).subscribe(a => {
        console.log(a);
        a.forEach(type=>{
          console.log(type);
          if(this.data.userId!=type.userId)
            {
              console.log(this.data.userId);
              this.buddyuserList.push(type);
            }
        })
        console.log("this.buddyuserList ",this.buddyuserList);
      })
         // Set buddyuser field as required
    this.userForm.get('buddyuser')?.setValidators(Validators.required);
    console.log(this.userForm);
    console.log(this.userForm.invalid);
    this.userForm.patchValue({
      buddyuser : this.data.buddyUser.userId
      })
    }
    else {
      // Clear buddyuser validators
      this.userForm.get('buddyuser')?.clearValidators();
      console.log(this.userForm);
      console.log(this.userForm.invalid);
    }

    // Update validation status for the buddyuser field
    this.userForm.get('buddyuser')?.updateValueAndValidity();
    console.log(this.userForm);
    console.log(this.userForm.invalid);
    



    this.gs.get_usertype().subscribe(a => {
      console.log(a)
      let userTypeId = this.auth.get_local_userType()

      if (userTypeId == 1) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4
        })
        this.userTypeList = primary_user
      }

      if (userTypeId == 2) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4 && u.userTypeId !== 2
        })
        this.userTypeList = primary_user
      }
    })
  }




  omit_special_char(event: { keyCode: any; charCode: any; }) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  get f() {
    return this.userForm.controls;
  }

  onUserTypeChange(event: MatSelectChange): void {
    const selectedUserTypeId = event.value;
    console.log('Selected User Type ID:', selectedUserTypeId);
    this.selectedUserTypeId = selectedUserTypeId;
    if(selectedUserTypeId == 3){
      this.gs.getbuddyuserByorg(2,this.organizationId).subscribe(a => {
        // console.log(a);
        // this.buddyuserList = a;
        console.log(a);
        a.forEach(type=>{
          console.log(type);
          if(this.data.userId!=type.userId)
            {
              console.log(this.data.userId);
              this.buddyuserList.push(type);
            }
        })
      })
    // Set buddyuser field as required
    this.userForm.get('buddyuser')?.setValidators(Validators.required);
    console.log(this.userForm);
    console.log(this.userForm.invalid);
    } else {
      // Clear buddyuser validators
      this.userForm.get('buddyuser')?.clearValidators();
      console.log(this.userForm);
      console.log(this.userForm.invalid);
    }

    // Update validation status for the buddyuser field
    this.userForm.get('buddyuser')?.updateValueAndValidity();
    console.log(this.userForm);
    console.log(this.userForm.invalid);
  }

  update_user() {
    if(this.userForm.invalid){
      console.log("invaild");
      return
    }
    let x= this.userForm.value
    console.log("x form data")
    console.log(x);
    
    //this.isLoading = true
    console.log("this.data.password"+ this.data.password);
    let org = this.auth.getLocal_Org()
    let user = this.auth.getLocalStorageData()
    console.warn(this.isOrg)

    if (this.isOrg) {
      let z = {
        userId : this.data.userId,
        name: x.name,
        phoneNumber: x.mobileNumber,
        email: x.email,
        password:  this.data.password,
        authenticationType: "null",
        createdByUser: user.userId,
        // address: org.address,
        // postalCode: org.postalCode,
        alternatePhoneNumber: x.mobileNumber,
        organization: {
          organizationId: org.organizationId
        },
        attachment:{
          attachmentId:this.empStatus.attachment.attachmentId,
          fileName:this.filename || this.empStatus.attachment.fileName,
          fileType:this.fileType || this.empStatus.attachment.fileType,
          fileSize:this.fileSize || this.empStatus.attachment.fileSize,
          attachmentByte :{
            attachmentByteId:this.empStatus.attachment.attachmentByte.attachmentByteId,
            fileByte :this.fileByte || this.empStatus.attachment.attachmentByte.fileByte
          }

        },
        // status:this.data.status,
        userType: [
          {
            userTypeId: x.userType
          }
        ],
        employee:{
          employeeId : this.data.employee.employeeId,
          employeeNumber:x.EmpNo,
          designation:{
              designationId:x.designation
          },
          status:{
            listTypeValueId:this.data.employee.status.listTypeValueId
          }
        },
        // states: {
        //   stateId: org.state.stateId
        // },
        // country: {
        //   countryId: org.country.countryId
        // },
        // city: {
        //   cityId: org.city.cityId
        // }
      }
      if(this.selectedUserTypeId == 3){
        z["buddyUser"] = {
          userId:x.buddyuser
        }
      }
      else{
        z["buddyUser"] = null
      }

      console.log(JSON.stringify(z))
      this.isLoading = true
      this.gs.Update_Org_User(z, this.data.userId).subscribe((a: any) => {
        this.isLoading = false
        this.dialog.closeAll()
        Swal.fire(
          'User Updated Successfully',
          "",
          'success'
        )
        console.log(a)
      },
        err => {
          console.log
          this.isLoading = false
          this.dialog.closeAll()
          Swal.fire(
            err.error.message,
            "",
            'error'
          )
        }
      )
    } else {
      let z = {
        userId : this.data.userId,
        name: x.name,
        phoneNumber: x.mobileNumber,
        email: x.email,
        password:  this.data.password,
        authenticationType: "null",
        createdByUser: user.userId,
        alternatePhoneNumber: x.mobileNumber,
        organization: {
          organizationId: org.organizationId
        },
        // status:this.data.status,
        // country: {
        //   countryId: user.country.countryId
        // },
        // states: {
        //   stateId: user.states.stateId
        // },
        // city: {
        //   cityId: user.city.cityId
        // },
        userType: [
          {
            userTypeId: x.userType
          }
        ],
        attachment:{
          attachmentId:this.empStatus.attachment.attachmentId,
          fileName:this.filename || this.empStatus.attachment.fileName,
          fileType:this.fileType || this.empStatus.attachment.fileType,
          fileSize:this.fileSize || this.empStatus.attachment.fileSize,
          attachmentByte :{
            attachmentByteId:this.empStatus.attachment.attachmentByte.attachmentByteId,
            fileByte :this.fileByte || this.empStatus.attachment.attachmentByte.fileByte
          }

        },
        employee:{
          employeeId : this.data.employee.employeeId,
          employeeNumber:x.EmpNo,
          designation:{
              designationId:x.designation
          },
          status:{
            listTypeValueId:this.data.employee.status.listTypeValueId
          }
        }
        // postalCode: user.postalCode,
        // address: user.address,
      }
      if(this.selectedUserTypeId == 3){
        z["buddyUser"] = {
          userId:x.buddyuser
        }
      }
      else{
        z["buddyUser"] = null
      }

      console.log("user with individual", z)
      console.log(JSON.stringify(z))
      this.isLoading = true
      this.gs.Update_Org_User(z , this.data.userId).subscribe((a: any) => {
        this.isLoading = false
        this.dialog.closeAll()
        Swal.fire(
          "User Created Successfully",
          "",
          'success'
        )
        console.log(a)
      },
        err => {
          console.log
          this.isLoading = false
          this.dialog.closeAll()
          Swal.fire(
            err.error.message,
            "",
            'error'
          )
        }
      )

    }
  }
//   getFileDetails(event) {
//     const files = event.target.files;
//     const fileInput = event.target as HTMLInputElement;
//     const filePath = fileInput.value;
//     const allowedExtensions = /(\.png|\.jpeg|\.jpg)$/i;

//     if (!allowedExtensions.exec(filePath)) {
//       // Show validation error (as per the Angular form control setup)
//       this.userForm.controls['attachment'].setErrors({ pattern: true });
//       fileInput.value = ''; // Reset the input field
//     }
//  else {
//       if (event.target.files.length > 0) {
//         const file = event.target.files[0];
//         this.filename = file.name;
//         this.fileSize = file.size;
//         this.fileType = file.type;

//         let reader = new FileReader();

//         reader.onload = () => {
//           let x: any = reader.result;
//           let y: any = x.split(',');
//           this.fileByte = y[1];
//           /* group.patchValue({
//           fileSource : file,
//           fileBytes : sourceByteArray
//         }) */
//         };

//         reader.readAsDataURL(file);
//       }
     
//     }
//   //   this.userForm.patchValue({
//   //     attachment: this.data.attachment.fileName
//   // });
//   }


getFileDetails(event: any) {
  const files = event.target.files;
  const fileInput = event.target as HTMLInputElement;
  const filePath = fileInput.value;
  const allowedExtensions = /(\.png|\.jpeg|\.jpg)$/i;

  // Validate file extension
  if (!allowedExtensions.exec(filePath)) {
    this.userForm.controls['attachment'].setErrors({ pattern: true });
    fileInput.value = ''; // Clear input if invalid file type
  } else {
    if (files.length > 0) {
      const file = files[0];
      this.filename = file.name;
      this.fileSize = file.size;
      this.fileType = file.type;

      let reader = new FileReader();

      reader.onload = () => {
        let base64File = reader.result as string;
        let fileContent = base64File.split(',')[1];
        this.fileByte = fileContent;

        // Patch form with new attachment data
        this.userForm.patchValue({
          attachment: this.filename // Update form value with the new file name
        });
      };

      reader.readAsDataURL(file);
    }
  }
}

 

}
