import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-userview',
  templateUrl: './userview.component.html',
  styleUrls: ['./userview.component.scss']
})
export class UserviewComponent implements OnInit {
  profileImage: any;

  constructor(
    public matdilaog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private matdialogRef:MatDialogRef<UserviewComponent>,
    public gs:GlobalserviceService

  ) { }

  ngOnInit(): void {
   console.log(this.data);
   this.profileImage = this.data.attachment.fileViewUrl
   console.log("this.profileImage",this.profileImage);
   console.log("deviceID",this.data.deviceId);
   
  }
  close(){
    this.matdialogRef.close();
  }
  deviceIdReset(){
    let payload={
      userId: this.data.userId
    }
    this.gs.updateDeviceId(this.data.userId,this.data.organization.organizationId,payload).subscribe(
    (data:any)=>{
      console.log(data);
      this.matdialogRef.close("updated");
      Swal.fire(
        data.description,
        "",
        'success'
      )
    },
    err => {
      Swal.fire(
        err.error.message,
        "",
        'error'
      )
    })  
  }

}
