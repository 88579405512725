import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select/select';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  isOrg: boolean = false

  userTypeList: any[] = []
  buddyuserList:any =[];


  userForm!: FormGroup
  matcher = new MyErrorStateMatcher();
  isLoading: boolean = false;
  organizationId:any;
  selectedUserTypeId: any;
  designationlist:any =[];
  filename: any;
  fileSize: any;
  fileType: any;
  fileByte: any;
  hide = true;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private auth: AuthService,
    private gs: GlobalserviceService) { }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      EmpNo: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      // password: ['', Validators.required],
      password: [
        '', 
        [
          Validators.required,
          Validators.minLength(8),  
        Validators.maxLength(12),
          Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]{8,12}$')
        ]
      ],
      designation:['',Validators.required],
      userType: ['', Validators.required],
      buddyuser:[''],
      attachment:['',Validators.required]
    })
    if (this.auth.getLocal_Org()?.organizationId) {
      this.organizationId = this.auth.getLocal_Org().organizationId;

    }

    this.gs.getdesignation().subscribe(a => {
      console.log(a);
      this.designationlist = a;
    })
    if (this.auth.getLocal_Org()?.organizationId) {
      this.isOrg = true;
    } else {
      console.log("else");
      this.isOrg = false
    }

    this.gs.get_usertype().subscribe(a => {
      console.log(a);
      let userTypeId = this.auth.get_local_userType()

      if (userTypeId == 1) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4
        })
        console.log("primary_user1",primary_user);
        this.userTypeList = primary_user;
        console.log("this.userTypeList1",this.userTypeList);
      }

      if (userTypeId == 2) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4 && u.userTypeId !== 2
        })
        console.log("primary_user2",primary_user);
        this.userTypeList = primary_user;
        console.log("this.userTypeList2",this.userTypeList);
      }

      // if (a.length > 0) {
      //   const data = a.filter(u => {
      //     return u.userTypeId !== 1 && u.userTypeId !== 4
      //   })
      //   console.log(data)
      //   this.userTypeList = data
      // }


    })

  }

  togglePasswordVisibility() {
    this.hide = !this.hide; // Switch between true and false
  }
  
  // In your component class
  onUserTypeChange(event: MatSelectChange): void {
    const selectedUserTypeId = event.value;
    console.log('Selected User Type ID:', selectedUserTypeId);
    this.selectedUserTypeId = selectedUserTypeId;
    if(selectedUserTypeId == 3){
      this.gs.getbuddyuserByorg(2,this.organizationId).subscribe(a => {
        console.log(a);
        this.buddyuserList = a;
      })
    // Set buddyuser field as required
    this.userForm.get('buddyuser')?.setValidators(Validators.required);
    console.log(this.userForm);
    console.log(this.userForm.invalid);
    } else {
      // Clear buddyuser validators
      this.userForm.get('buddyuser')?.clearValidators();
      console.log(this.userForm);
      console.log(this.userForm.invalid);
    }

    // Update validation status for the buddyuser field
    this.userForm.get('buddyuser')?.updateValueAndValidity();
    console.log(this.userForm);
    console.log(this.userForm.invalid);
  }


  omit_special_char(event: { keyCode: any; charCode: any; }) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  get f() {
    return this.userForm.controls;
  }

  create_user() {
    let x = this.userForm.value;
    console.log(x);
    let org = this.auth.getLocal_Org()
    let user = this.auth.getLocalStorageData()

    console.warn(this.isOrg)
    console.log(this.userForm);
    if(this.userForm.invalid){
      return
    }

    if (this.isOrg) {
      console.log("isOrg");
      let z = {
        name: x.name,
        phoneNumber: x.phoneNumber,
        email: x.email,
        password: x.password,
        authenticationType: "null",
        createdByUser: user.userId,
        // address: org.address,
        // postalCode: org.postalCode,
        alternatePhoneNumber: x.phoneNumber,
        organization: {
          organizationId: org.organizationId
        },
        status: "Active",
        userType: [
          {
            userTypeId: x.userType
          }
        ],

        attachment:{
          fileName:this.filename,
          fileType:this.fileType,
          fileSize:this.fileSize,
          attachmentByte:{
            fileByte:this.fileByte
          }
        },
        employee:{
          employeeNumber:x.EmpNo,
          designation:{
              designationId:x.designation
          },
          status:{
            listTypeValueId:1
        }
          // organization: {
          //   organizationId: org.organizationId
          // },
          // userType: {
          //   userTypeId: x.userType
          // },
          // employee_status:{
          //   listTypeValueId: 1
          // },
          // created_by: user.userId,

        },
        // buddyUser:{
        //   userId:x.buddyuser
        // },

        // states: {
        //   stateId: org.state.stateId
        // },
        // country: {
        //   countryId: org.country.countryId
        // },
        // city: {
        //   cityId: org.city.cityId
        // }
      }
      if(this.selectedUserTypeId == 3){
        z["buddyUser"] = {
          userId:x.buddyuser
        }
      }
      else{
        z["buddyUser"] = null
      }

      console.log(JSON.stringify(z))
      this.isLoading = true
      this.gs.Create_Org_User(z).subscribe((a: any) => {
        this.isLoading = false
        this.dialog.closeAll()
        Swal.fire(
          'User Created Successfully',
          "",
          'success'
        )
        console.log(a)
      },
        err => {
          console.log("org error1",err.error.message);
          this.isLoading = false
          this.dialog.closeAll()
          Swal.fire(
            err.error.message,
            "",
            'error'
          )
        }
      )
    } else {
      console.log("User");
      let z = {
        name: x.name,
        phoneNumber: x.phoneNumber,
        email: x.email,
        password: x.password,
        authenticationType: "null",
        createdByUser: user.userId,
        alternatePhoneNumber: x.phoneNumber,
        organization: {
          organizationId: org.organizationId
        },
        status: "Active",
         userType: [
          {
            userTypeId: x.userType
          }
        ],
        employee:{
          employeeNumber:x.EmpNo,
          designation:{
              designationId:x.designation
          },
          status:{
            listTypeValueId:1
        }
        },
        // buddyUser:{
        //   userId:x.buddyuser
        // },
        // country: {
        //   countryId: user.country.countryId
        // },
        // states: {
        //   stateId: user.states.stateId
        // },
        // city: {
        //   cityId: user.city.cityId
        // },
        // userType: [
        //   {
        //     userTypeId: x.userType
        //   }
        // ],
        // postalCode: user.postalCode,
        // address: user.address,
      }
      if(this.selectedUserTypeId == 3){
        z["buddyUser"] = {
          userId:x.buddyuser
        }
      }
      else{
        z["buddyUser"] = null
      }

      console.log("user with individual", z)
      console.log(JSON.stringify(z))
      // return

      this.isLoading = true
      this.gs.Create_Org_User(z).subscribe((a: any) => {
        this.isLoading = false
        this.dialog.closeAll()
        Swal.fire(
          "User Created Successfully",
          "",
          'success'
        )
        console.log(a)
      },
        err => {
          console.log("else error2",err.error.message);
          this.isLoading = false
          this.dialog.closeAll()
          Swal.fire(
            err.error.message,
            "",
            'error'
          )
        }
      )
    }
  }

  getFileDetails(event) {
    const files = event.target.files;
    const fileInput = event.target as HTMLInputElement;
    const filePath = fileInput.value;
    const allowedExtensions = /(\.png|\.jpeg|\.jpg)$/i;

    if (!allowedExtensions.exec(filePath)) {
      // Show validation error (as per the Angular form control setup)
      this.userForm.controls['attachment'].setErrors({ pattern: true });
      fileInput.value = ''; // Reset the input field
    }else {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.filename = file.name;
        this.fileSize = file.size;
        this.fileType = file.type;

        let reader = new FileReader();

        reader.onload = () => {
          let x: any = reader.result;
          let y: any = x.split(',');
          this.fileByte = y[1];
          /* group.patchValue({
          fileSource : file,
          fileBytes : sourceByteArray
        }) */
        };

        reader.readAsDataURL(file);
      }
    }
  }
}
