import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { MatSelectChange } from '@angular/material/select';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-uploaduser',
  templateUrl: './uploaduser.component.html',
  styleUrls: ['./uploaduser.component.scss']
})
export class UploaduserComponent implements OnInit {

  isOrg: boolean = false
  csvRecords: any[] = [];
  header = true;
  final_json :any = [];
  userTypeList: any[] = []
  buddyuserList:any =[];
  organizationId:any;
  useruploadForm!: FormGroup
  matcher = new MyErrorStateMatcher();
  isLoading: boolean = false
  userfile :any;
  selectedUserTypeId: any;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private auth: AuthService,
    private gs: GlobalserviceService , 
    private ngxCsvParser: NgxCsvParser) { }


  ngOnInit(): void {

    if (this.auth.getLocal_Org()?.organizationId) {
      this.organizationId = this.auth.getLocal_Org().organizationId;

    }

    this.useruploadForm = this.fb.group({
      file: ['', Validators.required],
      userType: ['', Validators.required],
      // buddyuser:['']
    })

    if (this.auth.getLocal_Org()?.organizationId) {
      this.isOrg = true
    } else {
      console.log("else")
      this.isOrg = false
    }

    this.gs.get_usertype().subscribe(a => {
      console.log(a)
      let userTypeId = this.auth.get_local_userType()

      if (userTypeId == 1) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4
        })
        this.userTypeList = primary_user
      }

      if (userTypeId == 2) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4 && u.userTypeId !== 2
        })
        this.userTypeList = primary_user
      }

      // if (a.length > 0) {
      //   const data = a.filter(u => {
      //     return u.userTypeId !== 1 && u.userTypeId !== 4
      //   })
      //   console.log(data)
      //   this.userTypeList = data
      // }

    })

  }

  onUserTypeChange(event: MatSelectChange): void {
    const selectedUserTypeId = event.value;
    console.log('Selected User Type ID:', selectedUserTypeId);
    this.selectedUserTypeId = selectedUserTypeId;
    if(selectedUserTypeId == 3){
      this.gs.getbuddyuserByorg(2,this.organizationId).subscribe(a => {
        console.log(a);
        this.buddyuserList = a;
      })
    // Set buddyuser field as required
    this.useruploadForm.get('buddyuser')?.setValidators(Validators.required);
    console.log(this.useruploadForm);
    console.log(this.useruploadForm.invalid);
    } else {
      // Clear buddyuser validators
      this.useruploadForm.get('buddyuser')?.clearValidators();
      console.log(this.useruploadForm);
      console.log(this.useruploadForm.invalid);
    }

    // Update validation status for the buddyuser field
    this.useruploadForm.get('buddyuser')?.updateValueAndValidity();
    console.log(this.useruploadForm);
    console.log(this.useruploadForm.invalid);
  }

  get f() {
    return this.useruploadForm.controls;
  }

  onFileChange(ev:any) {
    
    this.userfile = ev.target.files[0];
    console.log('category_banner',  this.userfile ,  this.userfile.name);
  }

    uploaduser()
    {

      let userdata = this.useruploadForm.value
      console.log(userdata);

        
    //  const file = userdata.file;
    console.log('category_banner',  userdata.file,  userdata.file.name);
   
    this.ngxCsvParser.parse(this.userfile, { header: this.header, delimiter: ',' })
    .pipe().subscribe((result: any) => {

      console.log('Result', result);
      this.csvRecords = result;

      let org = this.auth.getLocal_Org()
    let user = this.auth.getLocalStorageData()

    console.log(this.isOrg);
    console.log(this.csvRecords.length);
            for(let i=0 ; i<this.csvRecords.length; i++)
            {
              if (this.isOrg) {
                let z = {
                  name: this.csvRecords[i].Name,
                  phoneNumber: this.csvRecords[i].PhoneNumber,
                  email:this.csvRecords[i].Email,
                  password: this.csvRecords[i].Password,
                  authenticationType: "null",
                  createdByUser: user.userId,
                  address: org.address,
                  postalCode: org.postalCode,
                  alternatePhoneNumber: this.csvRecords[i].PhoneNumber,
                  organization: {
                    organizationId: org.organizationId
                  },
          
                  userType: [
                    {
                      userTypeId: userdata.userType
                    }
                  ],                 
                }
                console.log(JSON.stringify(z))

                if(this.selectedUserTypeId == 3){
                  z["buddyUser"] = {
                    userId:userdata.buddyuser
                  }
                }
                else{
                  z["buddyUser"] = null
                }
                  this.final_json.push(z);
                
              } else {
                  
                let z = {
                  name: this.csvRecords[i].Name,
                  phoneNumber: this.csvRecords[i].PhoneNumber,
                  email:this.csvRecords[i].Email,
                  password: this.csvRecords[i].Password,
                  authenticationType: "null",
                  createdByUser: user.userId,
                  userType: [
                    {
                      userTypeId: userdata.userType
                    }
                  ],
                  postalCode: user.postalCode,
                  address: user.address,
                }
          
                console.log("user with individual", z)
                console.log(JSON.stringify(z))
                this.final_json.push(z);
          
              }
            }
          
               console.log(this.final_json);
               
                    this.isLoading = true
    this.gs.multipleuser(this.final_json).subscribe((a: any) => {
      this.isLoading = false
      this.dialog.closeAll()
      Swal.fire(
        'User Created Successfully',
        "",
        'success'
      )
         this.csvRecords.length = 0;
         this.final_json.length = 0;
      console.log(a)
     

      
    },
      err => {
        console.log
        this.isLoading = false
        this.dialog.closeAll()
        Swal.fire(
          err.error.description,
          "",
          'error'
        )
        this.csvRecords.length = 0;
        this.final_json.length = 0;
      
      } 
    )
    }, (error: NgxCSVParserError) => {
      console.log('Error', error);
    });
    }

}
