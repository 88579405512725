<h1 mat-dialog-title>Enter Alias Name</h1>
<div mat-dialog-content>
  <form [formGroup]="aliasForm">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Alias&nbsp;Name</mat-label>
      <input matInput formControlName="aliasName">
      <mat-error *ngIf="aliasForm.get('aliasName')?.hasError('required')">
        {{ errorMessage }}
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button (click)="onSubmit()" [disabled]="aliasForm.invalid">Submit</button>
</div>
